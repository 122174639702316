<template>
  <div class="container py-4 mt-0">
    <div class="row px-0">
      <div
        class="col-md-4 text-md-lef d-flex d-md-block items-center justify-content-center"
      >
        <img src="@/assets/images/orange__logo.png" alt="" />
      </div>
      <div class="col-md-4 d-flex justify-content-center my-3 my-md-0">
        <router-link to="/contact">Contact us</router-link>
        <router-link to="/terms" class="mx-4"> Terms of service</router-link>
        <router-link to="/privacy">Privacy</router-link>
        <router-link to="/faq" class="mx-4">FAQ</router-link>
      </div>
      <div
        class="col-md-4 flex justify-content-center align-items-center icons text-md-right"
        style="gap: 0.5rem"
      >
        <a href="https://www.facebook.com/owattsinc" target="_blank"
          ><img src="@/assets/images/fb__icon.png" target="_blank" alt=""
        /></a>
        <a href=""
          ><img src="@/assets/images/wa__icon.png" target="_blank" alt=""
        /></a>
        <a href="https://twitter.com/owattsinc" target="_blank"
          ><img src="@/assets/images/twitter__icon.png" alt=""
        /></a>
        <a href="https://www.instagram.com/owattsinc/" target="_blank"
          ><img src="@/assets/images/ig__icon.png" alt=""
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
a {
  color: #000;
  text-decoration: none;
}
.dark a {
  color: #bcbecb;
}

a:hover {
  color: #ff7e00;
}

@media (min-width: 768px) {
  .logo img {
    margin: 0px auto;
  }
}
</style>
