<template>
  <div class="layout">
    <AppHeader />
    <!-- <AppSidebar /> -->
    <div class="wrapper d-flex flex-column min-vh-100 bg-whitesmoke" :class="sidebarVisible ? 'show' : 'hide'">
      <div class="body flex-grow-1 px-0 px-md-3 my-4" :class="sidebarVisible ? 'container' : 'container'">
        <div class="container">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <DashboardSwitch />
            <!-- <Back /> -->
            <SearchBar />
          </div>
          <router-view />
        </div>
      </div>
      <AppSidebar />
      <AppFooter />
    </div>
  </div>
</template>
<script>
import WelcomeModal from '@/components/WelcomeModal.vue'
import AppFooter from '@/components/FooterComponentOne.vue'
import AppHeader from '@/components/DashboardHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import Back from '@/components/AppBackHistory.vue'
import DashboardSwitch from '@/components/DashboardSwitch.vue'
import SearchBar from '@/components/Search.vue'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    WelcomeModal,
    AppFooter,
    AppHeader,
    AppSidebar,
    Back,
    SearchBar,
    DashboardSwitch
  },

  data() {
    return {}
  },

  computed:{
    ...mapState(['access_token', 'sidebarVisible']),
  },

  mounted() {
   
    this.getOptions()
  },

  methods: {
    ...mapActions('auth', ['getOptions']),
    onResize() {
      if (window.innerWidth > 1200) {
        this.$store.commit('setSidebar', true)
      } else {
        this.$store.commit('setSidebar', false)
      }
    }
  },

  created() {
    window.addEventListener('resize', this.onResize)
  },
}
</script>

<style>
.bg-whitesmoke {
  background: #f5f5f5a6;
}
.layout .wrapper {
    width: 100%;
    will-change: auto;
    transition: padding .15s;
    padding-left: 15rem !important;
    min-height: 100vh !important;
}
.layout .wrapper.hide {
    padding-left: 0 !important;
}

@media (max-width:768px) {
  .layout .wrapper {
    padding-left: 0 !important;
    min-height: 100vh !important;
}
  
}
</style>
