<template>
  <div class="flex flex-col">
    <div
      class="d-flex justify-content-between mb-4 p-3 w-full bg-white shadow rounded-xl"
    >
      <h5 class="font-weight-bold mt-2 pl-2">{{ title }}</h5>
      <div class="btn-group" v-if="download">
        <button
          class="btn btn-save btn-sm dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <!-- <CIcon icon="cilCloudDownload" size="sm" /> -->
          <svg width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M6 20q-.825 0-1.412-.587Q4 18.825 4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413Q18.825 20 18 20Zm6-4l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11Z"/></svg>
        </button>
        <ul class="dropdown-menu">
          <li>
            <button class="small pl-2 mb-2" @click="generateReport">
              Download Pdf
            </button>
            <button class="small pl-2 mb-2" @click="downloadXlsx">
              Download Csv
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="w-full bg-white shadow rounded-xl overflow-scroll">
      <div class="p-3 my-3">
        <table
          class="table-borered table-striped w-full overflow-scroll"
          id="dataTable"
        >
          <thead class="bg-white">
            <tr>
              <th
                class="px-6 py-2 text-xs text-black"
                v-for="(column, index) in columns"
                :key="index"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-300">
            <tr
              class="text-center whitespace-nowrap"
              v-for="(data, index) of tableData"
              :key="index"
            >
              <td class="px-6 py-3 text-sm text-gray-500">{{ index }}</td>
              <td class="px-6 py-3">
                <div class="text-sm text-gray-900">
                  {{ $filter.moment(data.created_at, 'DD-MM-YYYY') }}
                </div>
              </td>
              <td class="px-6 py-3">
                <div class="text-sm text-black">
                  {{ data.data.from_customer_no }}
                </div>
              </td>

              <td class="px-6 py-3 text-sm text-black">
                {{ data.data.to_customer_no }}
              </td>
              <td class="px-6 py-3">{{ data.currency }} {{ data.amount }}</td>
              <td class="px-6 py-3">
                {{ data.data.reference_no }}
              </td>
              <td class="px-6 py-3">
                <div class="text-sm text-black">
                  {{ data.description }}
                </div>
              </td>
              <td class="px-6 py-3">
                <div class="btn-group">
                  <router-link
                    :to="{ path: `/payments/${data.id}` }"
                    class="btn btn-icon btn-sm"
                  >
                    <img
                      src="@/assets/icons/eye.png"
                      alt=""
                      style="width: 20px"
                    />
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <vue3-html2pdf
    v-if="download"
    class="hidden"
    :show-layout="false"
    :float-layout="false"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="1000"
    filename="transactions"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    :pdf-margin="20"
    :pdf-padding="20"
    pdf-orientation="landscape"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <div class="div m-5 text-center">
        <div class="text-center w-100 mx-auto">
          <img
            src="@/assets/images/orange__logo.png"
            alt=""
            class="text-center"
          />
          <!-- <p>{{ auth.user.email }}</p> -->
          <div class="mb-5">
            <h5 class="font-weight-bold">{{ title }} History</h5>
            <small>{{
              new Date().toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            }}</small>
          </div>
          <table
            class="table-borered table-striped w-full overflow-scroll"
            id="dataTable"
          >
            <thead class="bg-white">
              <tr>
                <th
                  class="px-6 py-2 text-xs text-black"
                  style="padding: 2rem"
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-300">
              <tr
                class="text-center whitespace-nowrap"
                v-for="(data, index) of tableData"
                :key="index"
              >
                <td class="px-6 py-3 text-sm text-gray-500">{{ index }}</td>
                <td class="px-6 py-3">
                  <div class="text-sm text-gray-900">
                    {{ $filter.moment(data.created_at, 'DD-MM-YYYY') }}
                  </div>
                </td>
                <td class="px-6 py-3">
                  <div class="text-sm text-black">
                    {{ data.data.from_customer_no }}
                  </div>
                </td>

                <td class="px-6 py-3 text-sm text-black">
                  {{ data.data.to_customer_no }}
                </td>
                <td class="px-6 py-3">{{ data.currency }} {{ data.amount }}</td>
                <td class="px-6 py-3">
                  {{ data.data.reference_no }}
                </td>
                <td class="px-6 py-3">
                  <div class="text-sm text-black">
                    {{ data.description }}
                  </div>
                </td>
                <td class="px-6 py-3">
                  <div class="btn-group">
                    <router-link
                      :to="{ path: `/payments/${data.id}` }"
                      class="btn btn-icon btn-sm"
                    >
                      <img
                        src="@/assets/icons/eye.png"
                        alt=""
                        style="width: 20px"
                      />
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </vue3-html2pdf>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DownloadComponent from '@/components/DownloadComponent.vue'
import Vue3Html2pdf from 'vue3-html2pdf'
import { jsontoexcel } from 'vue-table-to-excel'

export default {
  props: ['tableData', 'download', 'columns', 'title'],
  computed: {
    ...mapState('auth', ['auth']),
  },

  components: {
    Vue3Html2pdf,
    DownloadComponent,
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },

    downloadXlsx() {
      const keys = Object.keys(this.payments.data[0])

      jsontoexcel.getXlsx(this.payments.data, keys, 'transactios.xlsx')
    },
  },

  beforeMount() {
    let dataTableScriptOne = document.createElement('script')
    dataTableScriptOne.setAttribute(
      'src',
      'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js',
    )
    dataTableScriptOne.id = 'scriptOne'

    // Scriipt Two
    let dataTableScriptTwo = document.createElement('script')
    dataTableScriptTwo.setAttribute(
      'src',
      'https://cdn.datatables.net/1.10.25/js/jquery.dataTables.js',
    )
    dataTableScriptTwo.id = 'scriptTwo'

    //
    document.head.appendChild(dataTableScriptOne)
    document.head.appendChild(dataTableScriptTwo)

    // setInterval(() => {
    //   $(document).ready(function () {
    //     $('#dataTable').DataTable()
    //   })
    // }, 2000)
  },

  mounted() {
    $(document).ready(function () {
      $('#dataTable').DataTable()
    })
  },

  beforeUnmount() {
    let elem = document.getElementById('scriptOne')
    let elem2 = document.getElementById('scriptTwo')
    // elem.parentNode.removeChild(elem)
    // elem2.parentNode.removeChild(elem2)
  },
}
</script>

<style>
.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: transparent;
  border: 1px solid fff;
  padding: 0;
  color: #000;
  margin: 0px 0.3rem;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: transparent;
  border: 1px solid #ffc107 !important;
  padding: 0;
  color: #000;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: transparent;
  border: 1px solid #ffc107 !important;
  padding: 0;
  color: #000;
}
</style>
