import request from '@/services/axios'
import config from '@/config/'
const baseUrl = config.baseUrl

export default {
  namespaced: true,
  state: {
    invoices: [],
    invoice: {},
  },
  mutations: {
    SET_INVOICES(state, data) {
      state.payments = data
    },

    SET_INVOICE(state, data) {
      state.payment = data
    },
  },
  actions: {
    async getInvoices({ commit }, query) {
      const url = new URL(baseUrl + `invoices`)
      url.search = new URLSearchParams(query)
      const res = await request().get(url)
      commit('SET_INVOICES', res.data.data)
      //window.scrollTo(0, 0)
      return res.data.data
    },

    async getInvoice({ commit }, id) {
      let res = await request().get(`/payments/${id}`, {
        'Content-Type': 'application/json',
      })
      commit('SET_INVOICE', res.data.data)
      return res.data.data
    },

    async payInvoice({ commit, rootState, dispatch }, trxData) {
      const res = await request().post(baseUrl + `payments`, trxData, {})
      return res.data.data
    },
  },
  getters: {
    invoices: (state) => {
      return state.invoices
    },
    invoice: (state) => {
      return state.invoice
    },
  },
}
