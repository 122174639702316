import request from '@/services/lifestyle/axios'

export default {
  namespaced: true,
  state: {
    profiles: [],
    projects: [],
    userProfile: '',
    userImage: null,
  },
  mutations: {
    setProfiles(state, data) {
      state.profiles = data.data
      // console.log(state.profiles)
    },
    addProfile(state, data) {
      state.profiles.unshift(data)
    },
    updateProfile(state, data) {
      let index = state.profiles.map((profile) => profile.id).indexOf(data.id)
      state.profiles.splice(index, 1, data)
    },
    deleteProfile(state, id) {
      let index = state.profiles.map((profile) => profile.id).indexOf(id)
      state.profiles.splice(index, 1)
    },
    userProfile(state, data) {
      state.userProfile = data
    },
    userImage(state, data) {
      state.userImage = data
    },

    //PROJECT
    setProjects(state, data) {
      state.projects = data
    },
    addProject(state, data) {
      state.projects.unshift(data)
    },
    updateProject(state, data) {
      let index = state.projects.map((project) => project.id).indexOf(data.id)
      state.projects.splice(index, 1, data)
    },
    deleteProject(state, id) {
      let index = state.projects.map((project) => project.id).indexOf(id)
      state.projects.splice(index, 1)
    },
  },
  actions: {
    async getProfiles({ commit }) {
      let res = await request().get(`/profiles`)
      commit('setProfiles', res.data)

      return res.data
    },

    // getProfiles({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/profiles' : 'profiles'
    //     axios.get(url).then((response) => {
    //       commit('setProfiles', response.data.data)
    //       resolve(resolve)
    //     })
    //   })
    // },
    getProfile({ commit }, id) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute ? 'admin/profiles' : 'u/'
        request()
          .get(url + id)
          .then((response) => {
            resolve(response)
          })
      })
    },
    getProjects({ commit }, id) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute ? 'admin/profiles' : 'u/'
        request()
          .get(url + id + '/projects')
          .then((response) => {
            resolve(response)
          })
      })
    },
    // addProfile({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/profiles' : 'profiles'
    //     axios.post(url, payload).then((response) => {
    //       commit('addProfile', response.data.data)
    //       commit('setSuccessMessage', 'Profile added', { root: true })
    //       resolve(response)
    //     })
    //   })
    // },

    async addProfile({ commit }, payload) {
      let res = await request().post('/profiles', payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      // console.log(payload)
      commit('updateProfile', res)
      commit('setSuccessMessage', 'Profile updated', {
        root: true,
      })

      return res
    },
    // updateProfile({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/profiles' : 'profiles'
    //     axios.patch(url + '/' + payload.id, payload).then((response) => {
    //       commit('updateProfile', response.data.data)
    //       commit('setSuccessMessage', 'Profile updated', {
    //         root: true,
    //       })
    //       resolve(response)
    //     })
    //   })
    // },
    async updateProfile({ commit }, payload) {
      let res = await request().post('/profiles', payload)
      // console.log(payload)
      commit('updateProfile', res)
      commit('setSuccessMessage', 'Profile updated', {
        root: true,
      })

      return res
    },
    // deleteProfile({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/profiles' : 'profiles'
    //     axios.delete(url + '/' + id).then((response) => {
    //       commit('deleteProfile', id)
    //       commit('setSuccessMessage', 'Profile deleted', { root: true })
    //       resolve(response)
    //     })
    //   })
    // },

    async deleteProfile({ commit }, id) {
      let res = await request().delete('/profiles' + id)
      // console.log(payload)
      commit('deleteProfile', id)
      commit('setSuccessMessage', 'Profile deleted', { root: true })

      return res
    },

    //PROJECT
    // getProjects({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/projects' : 'projects'
    //     axios.get(url).then((response) => {
    //       commit('setProjects', response.data.data)
    //       resolve(resolve)
    //     })
    //   })
    // },
    // async getProjects({ commit }) {
    //   let res = await request().get(`/projects`)
    //   commit('setProjects', res.data)

    //   return res.data
    // },
    // async getProject({ commit }, id) {
    //   let res = await request().get(`/projects/${id}`)

    //   return res.data
    // },
    // getProject({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/projects' : 'projects'
    //     axios.get(url + '/' + id).then((response) => {
    //       resolve(response)
    //     })
    //   })
    // },
    // addProject({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute ? 'admin/projects' : 'projects'
    //     axios.post(url, payload).then((response) => {
    //       commit('addProject', response.data.data)
    //       commit('setSuccessMessage', 'Project added', { root: true })
    //       resolve(response)
    //     })
    //   })
    // },

    async updatePassword({ commit }, payload) {
      let res = await request().post('/account/password', payload)
      commit('updatePassword', res)

      return res
    },

    async updateSettings({ commit }, payload) {
      let res = await request().post('/account/options', payload)
      return res
    },
    async updateUserProfile({ commit }, payload) {
      let res = await request().post('/account/profile', payload)
      // console.log(payload)
      commit('userProfile', res)

      return res
    },
    async uploadImage({ commit }, payload) {
      let res = await request().post('/account/image', payload)
      commit('userImage', res.data.data.image_url)
      return res
    },
    async addProject({ commit }, payload) {
      let res = await request().post('/projects', payload)
      // console.log(payload)
      commit('addProject', response.data.data)
      commit('setSuccessMessage', 'Project added', { root: true })

      return res
    },
    updateProject({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute ? 'admin/projects' : 'projects'
        axios.patch(url + '/' + payload.id, payload).then((response) => {
          commit('updateProject', response.data.data)
          commit('setSuccessMessage', 'Project updated', {
            root: true,
          })
          resolve(response)
        })
      })
    },
    deleteProject({ commit }, id) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute ? 'admin/projects' : 'projects'
        axios.delete(url + '/' + id).then((response) => {
          commit('deleteProject', id)
          commit('setSuccessMessage', 'Project deleted', { root: true })
          resolve(response)
        })
      })
    },
  },
  getters: {
    profiles: (state) => {
      return state.profiles
    },
    projects: (state) => {
      return state.projects
    },
  },
}
