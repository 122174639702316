<template>
  <h4>Hi</h4>
  <div>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1000"
      filename="transactions"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      :pdf-margin="20"
      :pdf-padding="20"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div class="div m-5 text-center">
          <div class="text-center w-100 mx-auto">
            <img
              src="@/assets/images/orange__logo.png"
              alt=""
              class="text-center"
            />
            <!-- <p>{{ auth.user.email }}</p> -->
            <div class="mb-5">
              <h5 class="font-weight-bold">Payment History</h5>
              <small>{{
                new Date().toLocaleDateString('en-US', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }}</small>
            </div>
            <table class="table text-nowrap table-bordered table-striped">
              <thead>
                <tr>
                  <th class="border-bottom-0 w-5 text-center">Date</th>
                  <th class="border-bottom-0 text-center">From</th>
                  <th class="border-bottom-0 text-center">To</th>
                  <th class="border-bottom-0 text-center">GBC</th>
                  <th class="border-bottom-0 text-center">Reference</th>
                  <th class="border-bottom-0 text-center">Description</th>
                </tr>
              </thead>

              <tbody></tbody>
            </table>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf'
import { jsontoexcel } from 'vue-table-to-excel'

export default {
  data() {
    return {
      paymentData: '',
      loader: true,
      showtable: false,
    }
  },
  components: {
    Vue3Html2pdf,
  },
  computed: {},

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },

    downloadXlsx() {
      const keys = Object.keys(this.payments.data[0])

      jsontoexcel.getXlsx(this.payments.data, keys, 'transactios.xlsx')
    },
  },
}
</script>

<style></style>
