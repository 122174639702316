import { createStore, createLogger } from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
const baseUrl = 'https://uappat.owatts.io/api/v1/'

import config from '@/config/'
import shop from '../modules/shop/store/index'
import auth from '../modules/auth/store/index'
import dashboard from '../modules/user/dashboard/store/index'
import orders from '../modules/user/dashboard/views/orders/store/index'
import reservations from '../modules/user/dashboard/views/reservations/store/index'
import payments from '../modules/user/dashboard/views/payment/store/index'
import invoices from '../modules/user/dashboard/views/invoices/store/index'
import referrals from '../modules/user/dashboard/views/referrals/store/index'
import partners from '../modules/user/dashboard/views/partners/store/index'
import siteReport from '../modules/user/dashboard/views/sitereports/store/index'
import oBidder from '../modules/user/obidder/store/index'
import profiling from '../modules/user/dashboard/views/profiling/store/index'
import merchants from '../modules/merchants/store/index'
import marketplace from '../modules/user/dashboard/views/marketplace/store/index'
import blogs from '../modules/blogs/store/index'
// initial state
let initialState = {
  shop: shop.state,
  blogs: blogs.state,
  dashboard: dashboard.state,
  payments: payments.state,
  siteReport: siteReport.state,
  oBidder: oBidder.state,
  auth: auth.state,
  referrals: referrals.state,
  partners: partners.state,
  reservations: reservations.state,
  orders: orders.state,
  profiling: profiling.state,
  invoices: invoices.state,
  merchants: merchants.state,
  marketplace: marketplace.state,
}
const debug = process.env.NODE_ENV !== 'production'
let plugins = [
  createPersistedState({
    key: process.env.MIX_APP_NAME,
  }),
]
if (debug) plugins.push(createLogger())
export default createStore({
  modules: {
    shop,
    blogs,
    dashboard,
    payments,
    siteReport,
    oBidder,
    auth,
    referrals,
    partners,
    reservations,
    orders,
    profiling,
    invoices,
    merchants,
    marketplace,
  },
  plugins,
  state: {
    sidebarVisible: false,
    sidebarUnfoldable: false,
    access_token: null,

    successMessage: '',
    errorMessage: '',

    order: {
      address: '',
      eletricity_bill: '',
      total: '',
      products: [],
    },
    estates: '',
    estate: '',
    estate_user: '',
    user_token: [],
  },
  mutations: {
    toggleSidebar(state, payload) {
      state.sidebarVisible = payload
      // console.log(state.sidebarVisible)
    },
    setSidebar(state, payload) {
      state.sidebarVisible = payload
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = !payload.value
    },
    setSuccessMessage(state, successMessage) {
      state.successMessage = successMessage
    },
    SET_ERROR_MESSEGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },

    ACCOUNT(state, data) {
      state.auth = data.user
    },
    SET_IMAGE(state, data) {
      state.auth.image_url = data
    },
    OPTIONS_DATA(state, data) {
      state.options_data = data
    },
    RESERVATIONS(state, data) {
      state.reservations = data
    },
    RESERVATION(state, data) {
      state.reservation = data
    },
    USER_TOKEN(state, data) {
      state.user_token.push(data)
    },
    getReservations(state, data) {
      state.reservation = data
    },

    async LOGOUT(state) {
      state.auth = {}
      state.access_token = null
      state.successMessage = ''
      state.errorMessage = ''
      // delete axios.defaults.headers.common['Authorization']
      // localStorage.removeItem(process.env.VUE_APP_NAME)

      localStorage.removeItem('vuex')
      localStorage.claer()
      // localStorage.removeItem('vuex', 'dashboard');
      // localStorage.removeItem('vuex', 'oBidder');

      // localStorage.setItem('vuex', JSON.stringify(storeObj));
    },
    RESET(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key])
      })
    },
  },
  actions: {
    async getReservations({ commit }, reservations) {
      commit('RESERVATIONS', reservations)
    },
    async sendTokenToUser({ commit }, userToken) {
      // console.log(userToken)
      commit('USER_TOKEN', userToken)
    },
    async getReservation({ commit }, payload) {
      // console.log(payload)
      const res = await axios.get(baseUrl + 'orders/' + payload.id, {
        headers: {
          Authorization: 'Bearer' + payload.access_token,
        },
      })
      commit('RESERVATION', res.data.data)

      return res.data.data
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        axios.post('auth/logout').then((response) => {
          commit('RESET', null)
          commit('LOGOUT', null)
          resolve(response)
        })
      })
    },

    getStates({ state }, id) {
      if (typeof id == 'number') {
        return state.options.countries.find((c) => c.id == id).states
      }
      return
    },

    async getInvoice(reference) {
      let res = await axios.get(config.baseUrl + `g/invoices/${reference}`, {
        'Content-Type': 'application/json',
      })
      return res.data.data
    },
  },
  getters: {
    auth: (state) => state.auth,
    isDistributor: (state) =>
      state.auth.auth && state.auth.auth.user.is_distributor,
    authenticated: (state) => !!state.access_token,
    get_access_token: (state) => state.auth.access_token,
    getReservation: (state) => (id) => {
      return state.reservations.find((accessory) => accessory.id === id)
    },
  },
})
