// Import request/axios config
import request from '@/services/axios'

export default {
  namespaced: true,
  state: {
    blogs: null,
  },

  // Mutations
  mutations: {
    OPTIONS(state, data) {
      state.options = data
    },
  },

  // Actions
  actions: {},

  // Getters
  getters: {
    get_access_token: (state) => state.auth.access_token,
  },
}
