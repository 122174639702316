<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="font-bold inline-flex justify-center items-center w-full rounded-md px-4 py-2 p-0 bg-transparent text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        <img src="@/assets/images/avatar.png" alt="" style="width:50%" />

        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-4" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute menu-items right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }">
          <a href="#" :class="[
            active ? ' text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm hidden',
          ]"></a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
          <router-link :to="`${isMerchantDashboard ? '/merchants/products' : '/dashboard/overview'}`" :class="[
            'active  text-black block px-4 py-2 text-sm font-bold',
          ]">Dashboard</router-link>
          </MenuItem>
          <MenuItem v-slot="{ active }">
          <router-link to="/reservations" :class="[
            'active  text-black block px-4 py-2 text-sm font-bold',
          ]">View Orders</router-link>
          </MenuItem>
          <MenuItem v-slot="{ active }">
          <router-link to="wallet" :class="[
            'active  text-black block px-4 py-2 text-sm font-bold',
          ]">Wallets</router-link>
          </MenuItem>
          <MenuItem v-slot="{ active }">
          <a @click="logUserOut" href="#" :class="[
            'active  text-black block px-4 py-2 text-sm font-bold',
          ]">Logout</a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

export default {
  data() {
    return {
      payload: {
        currency: '',
        locale: '',
      },
    }
  },

  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },

  computed: {
    ...mapState('merchants', ['merchant', 'isMerchantDashboard']),
  },

  methods: {
    ...mapActions('auth', ['changeCurrency']),
    ...mapActions('auth', ['getCurrency']),
    ...mapMutations('auth', ['LOGOUT']),
    logUserOut() {
      this.LOGOUT()
      this.$router.push('/')
      this.$moshaToast(`You have been logged out`, {
        hideProgressBar: true,
        type: 'success',
        position: 'top-right',
      })
    },
    async setCurrency(a, b) {
      let payload = { currency: a, locale: b }

    },
  },

  async created() { },
}
</script>

<style scoped>
@media(max-width: 768px) {
  .menu-items {
    top: -7rem;
    left: 0;
  }
}
</style>
