<template>
  <div class="flex flex-col">
    <div
      class="d-flex justify-content-between mb-4 p-3 w-full bg-white rounded-xl"
    >
      <slot name="header"></slot>
      <div class="btn-group" v-if="download">
        <button
          class="btn h-8 btn-save btn-sm dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <!-- <CIcon icon="cilCloudDownload" size="sm" /> -->
                    <svg width="22" height="22" viewBox="0 0 24 24"><path fill="currentColor" d="M6 20q-.825 0-1.412-.587Q4 18.825 4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413Q18.825 20 18 20Zm6-4l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11Z"/></svg>

        </button>
        <ul class="dropdown-menu">
          <li>
            <button class="small pl-2 mb-2" @click="generateReport">
              Download Pdf
            </button>
            <button class="small pl-2 mb-2" @click="downloadXlsx">
              Download Csv
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="w-full bg-white rounded-xl">
      <div class="p-3 my-3">
        <table class="table text-nowrap table-hovered" v-bind:id="tableId">
          <thead class="bg-white">
            <tr>
              <th
                class="px-6 py-2 text-xs text-black"
                v-for="column in columns"
                :key="column.id"
              >
                {{ column }}
              </th>
            </tr>
          </thead>

          <tbody>
            <slot name="body"></slot>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <vue3-html2pdf
    v-if="download"
    class="hidden"
    :show-layout="false"
    :float-layout="false"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="1000"
    filename="transactions"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    :pdf-margin="20"
    :pdf-padding="20"
    pdf-orientation="landscape"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <div class="div m-5 text-center">
        <div class="text-center w-100 mx-auto">
          <img
            src="@/assets/images/orange__logo.png"
            alt=""
            class="text-center"
          />
          <!-- <p>{{ auth.user.email }}</p> -->
          <div class="mb-5">
            <h5 class="font-weight-bold">{{ title }} History</h5>
            <small>{{
              new Date().toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            }}</small>
          </div>
          <table
            class="table-borered table-striped w-full overflow-scroll"
            id="dataTable"
          >
            <thead class="bg-white">
              <tr>
                <th
                  class="px-6 py-2 text-xs text-black"
                  style="padding: 2rem"
                  v-for="column in columns"
                  :key="column.id"
                >
                  {{ column }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-300">
              <slot name="body"></slot>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </vue3-html2pdf>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DownloadComponent from '@/components/DownloadComponent.vue'
import Vue3Html2pdf from 'vue3-html2pdf'
import { jsontoexcel } from 'vue-table-to-excel'
export default {
  name: 'SmartDataTable',
  props: ['tableData', 'download', 'columns', 'title', 'tableId'],
  computed: {
    ...mapState('auth', ['auth']),
  },
  components: {
    Vue3Html2pdf,
    DownloadComponent,
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    downloadXlsx() {
      const keys = Object.keys(this.tableData[0])
      jsontoexcel.getXlsx(this.tableData, keys, `${this.tableId}.xls`)
    },
  },
  beforeMount() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js',
    )
    let recaptchaScript2 = document.createElement('script')
    recaptchaScript2.setAttribute(
      'src',
      'https://cdn.datatables.net/1.10.25/js/jquery.dataTables.js',
    )
    document.head.appendChild(recaptchaScript)
    document.head.appendChild(recaptchaScript2)
  },
  mounted() {
    let iid = this.tableId
    setTimeout(() => {
      $(document).ready(function () {
        $(`#${iid}`).DataTable()
      })
    }, 4000)
  },
}
</script>

<style>
.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: transparent;
  border: 1px solid fff;
  padding: 0;
  color: #000;
  margin: 0px 0.3rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: transparent;
  border: 1px solid #ffc107 !important;
  padding: 0;
  color: #000;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: transparent;
  border: 1px solid #ffc107 !important;
  padding: 0;
  color: #000;
}

td,
th {
  border-style: none;
}

table.dataTable.no-footer {
  border-bottom: inherit;
}
</style>
