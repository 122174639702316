import axios from 'axios'
import config from '@/config'
const baseUrl = config.baseUrl
// changeBaseUrl
import request from '@/services/lifestyle/axios'

// import axios from 'axios'
export default {
  namespaced: true,
  state: {
    questionaires: [],
    assessments: [],
  },
  mutations: {
    //questionaire
    setQuestionnairas(state, data) {
      state.questionaires = data
    },
    addQuestionnaira(state, data) {
      state.questionaires.unshift(data)
    },
    updateQuestionnaira(state, data) {
      let index = state.questionaires
        .map((questionaire) => questionaire.id)
        .indexOf(data.id)
      state.questionaires.splice(index, 1, data)
    },
    deleteQuestionnaira(state, id) {
      let index = state.questionaires
        .map((questionaire) => questionaire.id)
        .indexOf(id)
      state.questionaires.splice(index, 1)
    },

    //assessment
    setAssessment(state, data) {
      state.assessments = data
    },
    addAssessment(state, data) {
      state.assessments.unshift(data)
    },
    updateAssessment(state, data) {
      let index = state.assessments
        .map((assessment) => assessment.id)
        .indexOf(data.id)
      state.assessments.splice(index, 1, data)
    },
    deleteAssessment(state, id) {
      let index = state.assessments
        .map((assessment) => assessment.id)
        .indexOf(id)
      state.assessments.splice(index, 1)
    },
  },
  actions: {
    //questionaire
    // getQuestionnairas({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute
    //       ? 'admin/questionaires'
    //       : 'allquestionaires'
    //     axios.get(url).then((response) => {
    //       commit('setQuestionnairas', response.data.data)
    //       resolve(response.data)
    //     })
    //   })
    // },
    getQuestionnaira({ commit }, id) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute
          ? 'admin/questionaires'
          : 'questionaires'
        request()
          .get(url + '/' + id)
          .then((response) => {
            resolve(response)
          })
      })
    },

    async getQuestionnairas({ commit }) {
      const res = await request().get('/allquestionaires')
      commit('setQuestionaires', res.data)

      return res.data.data
    },
    async addQuestionnaira({ commit }, payload) {
      const res = await request().post('/questionaires', payload)

      commit('addQuestionnaira', res.data.data)
      return res
    },

    // async addQuestionnaira({ commit }, payload) {
    //   let res = await request().post(baseUrl + 'questionaires', payload, {})
    //
    //   commit('addQuestionnaira', res.data.data)
    //   return res
    // },
    // addQuestionnaira({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute
    //       ? 'admin/questionaires'
    //       : 'questionaires'
    //     axios.post(url, payload).then((response) => {
    //       commit('addQuestionnaira', response.data.data)
    //       commit('setSuccessMessage', 'Report added', { root: true })
    //       resolve(response)
    //     })
    //   })
    // },
    updateQuestionnaira({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute
          ? 'admin/questionaires'
          : 'questionaires'
        request()
          .put(url + '/' + payload.id, payload)
          .then((response) => {
            commit('updateQuestionnaira', response.data.data)
            commit('setSuccessMessage', 'questionaires updated', { root: true })
            resolve(response.data)
          })
      })
    },
    deleteQuestionnaira({ commit }, id) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute
          ? 'admin/questionaires'
          : 'questionaires'
        request()
          .delete(url + '/' + id)
          .then((response) => {
            commit('deleteQuestionnaira', id)
            commit('setSuccessMessage', 'questionaires deleted', { root: true })
            resolve(response.data)
          })
      })
    },
    // //assessment
    // getAssessments({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute
    //       ? 'admin/allassessments'
    //       : 'allassessments'
    //     axios.get(url).then((response) => {
    //       commit('getAssessments', response.data.data)
    //       resolve(response.data)
    //     })
    //   })
    // },
    // getAssessment({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute
    //       ? 'admin/assessments'
    //       : 'assessments'
    //     axios.get(url + '/' + id).then((response) => {
    //       resolve(response)
    //     })
    //   })
    // },

    async getAssessment({ commit }, payload) {
      // console.log(payload)
      let res = await request().get(baseUrl + 'assessments/' + payload, {})

      commit('addAssessment', res.data.data)
      return res
    },

    async getAssessments({ commit }) {
      const res = await request().get('/allassessments')
      commit('setAssessment', res.data.data)

      return res.data.data
    },

    async addAssessment({ commit }, payload) {
      let res = await request().post('assessments', payload)

      commit('addAssessment', res.data.data)
      return res
    },

    async updateAssessment({ commit }, payload) {
      // console.log(payload)
      let res = await request().put(
        baseUrl + 'assessments/' + payload.id,
        payload,
        {},
      )

      commit('addAssessment', res.data.data)
      return res
    },

    async deleteAssessment({ commit }, payload) {
      // console.log(payload)
      let res = await request().delete(
        baseUrl + 'assessments/' + payload.id,
        payload,
        {},
      )

      // commit('deleteAssessment', res.data.data)
      return res
    },

    // updateAssessment({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute
    //       ? 'admin/assessments'
    //       : 'assessments'
    //     axios.put(url + '/' + payload.id, payload).then((response) => {
    //       commit('updateAssessment', response.data.data)
    //       commit('setSuccessMessage', 'assessments updated', { root: true })
    //       resolve(response.data)
    //     })
    //   })
    // },
    // deleteAssessment({ commit }, id) {
    //   return new Promise((resolve, reject) => {
    //     let url = this.getters.isAdminRoute
    //       ? 'admin/assessments'
    //       : 'assessments'
    //     axios.delete(url + '/' + id).then((response) => {
    //       commit('deleteAssessment', id)
    //       commit('setSuccessMessage', 'assessments deleted', { root: true })
    //       resolve(response.data)
    //     })
    //   })
    // },
  },
  getters: {
    questionaires: (state) => state.questionaires,
    assessments: (state) => state.assessments,
  },
}
