import request from '@/services/lifestyle/axios'

export default {
  namespaced: true,
  state: {
    referrals: [],
  },
  metations: {
    SET_REFERRALS(state, data) {
      state.referrals = data
    },
    addTransaction(state, data) {
      state.transactions.unshift(data)
    },
  },
  actions: {
    async getReferrals({ commit }) {
      let res = await request().get(`/referrals`, {
        'Content-Type': 'application/json',
      })
      commit('SET_REFERRALS', res.data.data)

      return res.data.data
    },
  },
  getters: {
    transactions: (state) => {
      return state.transactions
    },
  },
}
