const BlogsHome = () => import('../views/Home')

import store from '@/store/index'

export default [
  {
    path: '/blogs',
    name: 'blogs',
    component: BlogsHome,
    meta: { layout: 'default' },
  },
]
