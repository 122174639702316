const EstateManagerDashboard = () =>
  import('../views/manager/EstateManagerDashboard')

// users
const Estates = () => import('../../obidder/views/Estates')
const Estate = () => import('../../obidder/views/Estate')
const EstateFlat = () => import('../../obidder/views/manager/EstateFlat')
const EstateFlatPayments = () => import('../../obidder/views/Payments')

const EstateFlatFeeInvoices = () =>
  import('../../obidder/views/EstateFlatFeeInvoices')

const EstateFlatFees = () => import('../../obidder/views/EstateFlatFees')

// manager
const EstateTariffs = () => import('../views/manager/EstateTariffs')
const EstateTariff = () => import('../views/manager/EstateTariff')
const EstateSettings = () => import('../views/manager/EstateSettings')
const EstateFlats = () => import('../views/manager/EstateFlats')
const EstateEnergyTransactions = () =>
  import('../views/manager/EstateEnergyTransactions')
const EstateEnergyConsumptions = () =>
  import('../views/manager/EstateEnergyConsumptions')
const EstateEnergyConsumptionsGraph = () =>
  import('../views/EstateComsumptionGraph')

const EstateFlatFeeInvoicesManager = () =>
  import('../../obidder/views/manager/EstateFlatFeeInvoices')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/obidder',
    name: 'obidder',
    component: DefaultLayout,
    redirect: '/dashboard/user',
    async beforeEnter(to, from, next) {
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '',
        name: 'obidder',
        component: Estate,
      },
      {
        path: 'consumptions/:imei',
        name: 'consumptions.graph',
        component: EstateEnergyConsumptionsGraph,
      },

      // Estate User Routes
      {
        path: 'estates',
        name: 'obidder-estates',
        component: Estates,
      },
      {
        path: 'estates/:id',
        name: 'obidder-estate',
        component: Estate,
      },

      // {
      //   path: 'estates/:id/transactions',
      //   name: 'obidder-estate_flat_transactions',
      //   component: EstateEnergyTransactions,
      // },

      // {
      //   path: 'estates/:id/transactions',
      //   name: 'obidder-estate_flat_consumptions',
      //   component: EstateEnergyConsumptions,
      // },

      {
        path: 'estates/:id/fees',
        name: 'obidder_estate_flat_fees',
        component: EstateFlatFees,
      },

      {
        path: 'estates/:estate_id/fees/:fee_id/invoices',
        name: 'obidder_estate_flat_fee_invoices',
        component: EstateFlatFeeInvoices,
      },
    ],
  },

  {
    path: '/obidder/manage',
    name: 'obidder-manager',
    component: DefaultLayout,
    async beforeEnter(to, from, next) {
      try {
        let loggedIn = await store.getters.get_access_token
        if (loggedIn) {
          let manager_estates = await store.getters['oBidder/manager_estates']
          let isManager = false
          let estateId = to.params.id

          await manager_estates.forEach((estate) => {
            if (estateId == estate.id) {
              isManager = true
            }
          })

          // protect page from non managers
          if (isManager) {
            next()
          } else {
            // let userUlr = to.path.replace('manage/', '')
            // next( { path: userUlr } )
            next({ name: from.name })
          }
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      // {
      //   path: 'estates',
      //   name: 'obidder-manage-estates',
      //   component: Estates,
      // },
      {
        path: 'estates/:id',
        name: 'obidder-manage-estate',
        component: EstateManagerDashboard,
      },

      {
        path: 'estates/:id/tariffs',
        name: 'obidder-manage-estate_tariffs',
        component: EstateTariffs,
      },

      {
        path: 'estates/:id/flats',
        name: 'obidder-manage-estate_flats',
        component: EstateFlats,
      },

      {
        path: 'estates/:id/transactions',
        name: 'obidder-manage-estate_transactions',
        component: EstateEnergyTransactions,
      },

      {
        path: 'estates/:id/consumptions',
        name: 'obidder-manage-estate_consumptions',
        component: EstateEnergyConsumptions,
      },

      {
        path: 'estates/:id/settings',
        name: 'obidder-manage-estate_settings',
        component: EstateSettings,
      },

      {
        path: 'estates/:id/tariffs/:tariff_id',
        name: 'obidder_manage_estate_tarrif',
        component: EstateTariff,
      },

      {
        path: 'estates/:id/flats/:flat_id',
        name: 'obidder_manage_estateflat',
        component: EstateFlat,
      },

      {
        path: 'estates/:estate_id/fees/:fee_id/invoices',
        name: 'obidder_manager_estate_flat_fee_invoices',
        component: EstateFlatFeeInvoicesManager,
      },

      {
        path: 'estates/user/:id',
        name: 'obidder-estateUser',
        component: EstateFlat,
      },
      {
        path: 'estates/user/payments',
        name: 'obidder-estateUserPayments',
        component: EstateFlatPayments,
      },
    ],
  },
]
