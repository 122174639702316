<template>
  <div>
    <div class="bg-black fixed w-100 top-0 left-0" style="z-index: 999">
      <AppHeader />
    </div>
    <div class="wrapper d-flex flex-column min-vh-100 bg-whitesmoke mt-[50px]">
      <div class="body flex-grow-1 px-0 px-md-3 my-4">
        <div class="container" lg>
          <router-view />
        </div>
      </div>

      <AppFooter />
    </div>
  </div>
</template>
<script>


import AppFooter from '@/components/FooterComponentOne.vue'
import AppHeader from '@/components/HomeHeaderComponent.vue'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
  },

  data() {
    return {}
  },

  mounted() {},

  methods: {
    ...mapActions('auth', ['getOptions']),
  },

  created() {},
}
</script>

<style>
.bg-whitesmoke {
  background: #f5f5f5a6;
}
</style>
