const Referrals = () => import('../views/user/Referrals')
const ReferralDetails = () => import('../views/user/ReferralDetails')

const AdminTransactions = () => import('../views/admin/Transactions')
const AdminTransactionDetails = () =>
  import('../views/admin/TransactionDetails')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/referrals',
    name: 'Referrals',
    component: DefaultLayout,
    redirect: '/referrals',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/referrals',
        name: 'user.referrals.index',
        component: Referrals,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Referrals',
        },
      },
      {
        path: '/referrals/:id',
        name: 'user.referrals.show',
        component: ReferralDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Referral Details',
        },
      },
      {
        path: '/admin/transactions',
        name: 'admin.transactions.index',
        component: AdminTransactions,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-transaction',
          title: 'Transactions',
        },
      },
      {
        path: '/admin/transactions/:id',
        name: 'admin.transactions.show',
        component: AdminTransactionDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-transaction',
          title: 'Transaction Details',
        },
      },
    ],
  },
]
