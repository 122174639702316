<template>
  <div class="">
    <nav
      class="navbar navbar-expand-md navbar-light container sticky-top"
      id="navbar_top"
    >
      <router-link to="/" class="navbar-brand"
        ><img src="@/assets/images/logo.png" alt=""
      /></router-link>
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src="@/assets/images/menu__icon.png" alt="" />
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavId">
        <button
          class="navbar-toggler d-lg-none close_nav--btn"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavId"
          aria-controls="collapsibleNavId"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="text-white">&times;</span>
        </button>

        <ul class="navbar-nav mr-auto ml-auto mt-2 mt-lg-0">
          <li
            class="nav-item"
            :class="this.$route.name == 'Home' ? 'active' : ''"
          >
            <router-link class="nav-link" to="/"
              >Home <span class="sr-only">(current)</span></router-link
            >
          </li>

          <li
            class="nav-item"
            :class="this.$route.name == 'Shop' ? 'active' : ''"
          >
            <router-link class="nav-link" to="/shop">Shop</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/join-the-solution"
              >Join</router-link
            >
          </li>
          <li
            v-if="auth"
            class="nav-item"
            :class="this.$route.name == 'Accessories' ? 'active' : ''"
          >
            <a class="nav-link" href="#">Accessories </a>
          </li>
          <!-- <li
            class="nav-item"
            :class="this.$route.name == 'Marketplace' ? 'active' : ''"
          >
            <router-link class="nav-link" to="/marketplace/products"
              >Owatts Market Place
            </router-link>
          </li>
          <li
            class="nav-item"
            :class="this.$route.name == 'Marketplace' ? 'active' : ''"
          >
            <router-link class="nav-link" to="/blogs">Blog </router-link>
          </li> -->
        </ul>
        <div
          class="header__icons d-flex align-items-center"
          style="gap: 2rem"
          v-if="access_token"
        >
          <span
            >
            <!-- <CIcon icon="cil-magnifying-glass" style="color: #ff7e00"
          /> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>

          </span>
          <router-link to="/cart" class="ca t position-relative"
            >
            <span class="badge" v-if="cart && cart.length"> {{ cart.length }} </span>
            <img
              v-if="cart.length > 0"
              src="@/assets/images/c.png"
              alt=""
              class="mx-4"
              width="100%"
              height="100%"
              style="width: 27px"
            />
           
            <img
              v-else
              src="@/assets/images/empty.png"
              alt=""
              class="mx-4"
              width="100%"
              height="100%"
              style="width: 27px"
            />
            
          </router-link>
       
        </div>
        <div
          class="header__icons d-flex align-items-center"
          style="gap: 1rem"
          v-else
        >
          <span
            > 
            <!-- <CIcon icon="cil-magnifying-glass" style="color: #ff7e00"
          /> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
</svg>

          </span>
          <router-link to="/cart" class="ca t"
            ><img
              v-if="cart.length > 0"
              src="@/assets/images/c.png"
              alt=""
              class="mx-4"
              style="width: 27px"
            />
            <img
              v-else
              src="@/assets/images/empty.png"
              alt=""
              class="mx-4"
              style="width: 27px"
            />
          </router-link>
          <div>
            <router-link to="/login" class="btn text-white">Login</router-link>
          </div>
          <div>
            <router-link to="/register">
              <button class="btn btn-white">Register</button></router-link
            >
          </div>
        </div>
        <NavDropdown  v-if="access_token"/>
      </div>
    </nav>
    <!-- END nav -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import NavDropdown from "@/components/NavDropdown.vue";
// import hero from "./appHero";
export default {
  components: {
    // hero hero,
    NavDropdown
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('shop', ['isLoggedIn', 'cart']),
    ...mapState('auth', ['auth', 'access_token']),
  },

  methods: {
    ...mapMutations('auth', ['LOGOUT']),
    logUserOut() {
      this.LOGOUT()
      this.$router.push('/')
      this.$moshaToast(`You have been logged out`, {
        hideProgressBar: true,
        type: 'success',
        position: 'top-right',
      })
    },
  },

  created() {},
}
</script>

<style>
.dropdown-item {
  cursor: pointer !important;
}
</style>

<style scoped>
.dropdown-toggle::after {
  display: none;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: max-content;
}
button:focus {
  outline: none;
  border: none;
}
img {
  /* width: 20.04px; */
  cursor: pointer;
}
.cart {
  position: relative;
}

.cart::after {
  content: '';
  text-align: center;
  font-size: xx-small;
  padding-top: 0.15rem;
  position: absolute;
  right: 9px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #ff7e00;
  top: -5px;
  color: #fff;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}
.close_nav--btn {
  position: absolute;
  top: 0.4rem;
  right: 1rem;
  font-size: larger;
}
.shadow {
  box-shadow: 0 0.5rem 0.6rem rgb(0 0 21 / 15%) !important;
}
.badge{
  position: absolute;
  width: 10px;
    height: 10px;
    color: #000;
    background: #ff7e00;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -5px;
    right: 20px;
}
</style>
