<template>
  <div>
    <!-- Button trigger modal -->
    <button
      type="button"
      class=""
      data-bs-toggle="modal"
      data-bs-target="#myModal2"
    ></button>

    <!-- Modal -->
    <div
      class="Mymodal modal fade"
      id="myModal2"
      tabindex="-1"
      aria-labelledby="myModal2Label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myModal2Label">
              Welcome to your platform.
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h6 class="mb-4">
              The most important thing you will find is the ability to monitor
              your solar generation, clean gas, water, utility, storage and
              electricity usage through our advanced BlockChain monitoring
              technology we collect daily, hourly, and almost real-time usage
              data. We will then report that directly to you through this site.
            </h6>
            <h6 class="mb-4">
              For you, knowing exactly what you have consumed and when it was
              consumed will have significant value to you. In addition, studies
              show that businesses and homeowners are likely to conserve more
              energy when they can easily monitor their consumption. So not only
              do you have the ability to save money, but it is likely that you
              will begin conserving energy.
            </h6>
            <h6 class="mb-4">
              Advanced data monitoring is a key step to modernising our utility
              - water, energy, power, mobility and payment infrastructure.
              Identifying consumer behaviour will improve the process of
              managing demand for natural resources, and will lead to an
              improved quality of service. It’s a win-win situation – utilities
              save resources and customers save money on their bills. This is
              why we make it such a critical component of our service offering
              to you.
            </h6>
            <h6 class="mb-4">
              We recommend that you utilize this area as much as possible
              because besides reporting to you your device information, you also
              get to monitor and control every features of your active
              appliances at home or in the business place.
            </h6>

            <h6>
              Remember, your owatts benefits include:
              <ul>
                <li>
                  •Ability to monitor and control your device remotely •A fixed
                  or flexible tariff rate
                </li>
                <li>•Ability to monitor and control your device remotely</li>
                <li>•A fixed or flexible tariff rate</li>
                <li>•No investment risk</li>
                <li>•Performance guarantee</li>
                <li>•Hassle-free installation</li>
                <li>•Dependable maintenance</li>
                <li>•Timely gas and energy analysis</li>
                <li>•Environmental and climate change saving reporting</li>
                <li>
                  •Data driving cost saving information. There are a number of
                  other things that you can do here, as well.
                </li>
              </ul>

              The following are few things that can be done.
              <ul>
                <li>
                  •Stay in touch with news and updates regarding your
                  installation
                </li>
                <li>•Update your customer information and profile</li>
                <li>
                  •Retrieve and pay your bills •Participate in our associate
                  program and start a business
                </li>
                <li>•Refer friends and family and earn income</li>
                <li>
                  •Provide feedback to us on what we can do better. Take
                  advantage of the Customer's Area.
                </li>
              </ul>
              We look forward to delivering you with timely and valuable
              information. Thank you, once again, for being a part of OWATTS.
              <h6>Yours in success,</h6>
              <h6>OWATTS</h6>
            </h6>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  created() {},
}
</script>

<style scoped>
li {
  margin: 0.5rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
  }
}

.modal-backdrop.show {
  display: none !important;
}
.modal-dialog-scrollable .modal-content {
  border: none;
}
</style>
