// AppSidebar
<template>
  <section position="fixed" :unfoldable="sidebarUnfoldable" :visible="!sidebarVisible" @visible-change="(event) =>
    $store.commit({
      type: 'updateSidebarVisible',
      value: event,
    })
    " class="sidebar sidebar-fixed" :class="sidebarVisible ? 'visible' : 'hidden'" style="background: #fff !important">
    <div class="sidebar-nav">
      <!-- Logo -->

      <router-link to="/" class="sidenav-logo d-md-none">
        <div class="nav-item">
          <img src="@/assets/images/orange__logo.png" alt="" />
        </div>
      </router-link>

      <!-- Logo -->

      <router-link :to="`${isMerchantDashboard ? '/merchants/products' : '/dashboard/overview'}`">
        <li class="nav-item">
          <svg width='20' height='20' class="mr-3" viewBox="0 0 512 512">
            <path fill="currentColor"
              d="M425.706 142.294A240 240 0 0 0 16 312v88h144v-32H48v-56c0-114.691 93.309-208 208-208s208 93.309 208 208v56H352v32h144v-88a238.432 238.432 0 0 0-70.294-169.706Z" />
            <path fill="currentColor"
              d="M80 264h32v32H80zm160-136h32v32h-32zm-104 40h32v32h-32zm264 96h32v32h-32zm-102.778 71.1l69.2-144.173l-28.85-13.848l-69.183 144.135a64.141 64.141 0 1 0 28.833 13.886ZM256 416a32 32 0 1 1 32-32a32.036 32.036 0 0 1-32 32Z" />
          </svg>
          <span class="nav-link">Dashboard</span>
        </li>
      </router-link>


      <template v-if="!isMerchantDashboard">
        <li class="nav-title text-dark my-0">Application</li>

        <router-link to="/orders">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 512 512">
              <path fill="currentColor"
                d="m222.085 235.644l-62.01-62.01L81.8 251.905l62.009 62.01l-.04.04l66.958 66.957l11.354 11.275l.04.039l66.957-66.957l11.273-11.354l202.277-202.271l-78.272-78.271Zm44.33 66.958l-11.274 11.353l-33.057 33.056l-.04-.039l-33.017-33.017l.04-.04l-62.009-62.01l33.016-33.016l62.01 62.009L424.356 78.627l33.017 33.017Z" />
              <path fill="currentColor" d="M448 464H48V64h300.22l32-32H16v464h464V179.095l-32 32V464z" />
            </svg>
            <span class="nav-link">Orders</span>
          </li>
        </router-link>

        <!-- <router-link to="/reservations">
        <li class="nav-item">
          <svg width="20" height="20" class="mr-3" viewBox="0 0 2048 2048">
            <path
              fill="currentColor"
              d="M896 512v128H512V512h384zM512 896V768h384v128H512zm0 256v-128h256v128H512zM384 512v128H256V512h128zm0 256v128H256V768h128zm-128 384v-128h128v128H256zM128 128v1792h640v128H0V0h1115l549 549v219h-128V640h-512V128H128zm1024 91v293h293l-293-293zm640 805h256v1024H896V1024h256V896h128v128h384V896h128v128zm128 896v-512h-896v512h896zm0-640v-128h-896v128h896z"
            />
          </svg>
          <span class="nav-link">Reservations</span>
        </li></router-link
      > -->

        <router-link to="/payments">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="M14 13q1.25 0 2.125-.875T17 10q0-1.25-.875-2.125T14 7q-1.25 0-2.125.875T11 10q0 1.25.875 2.125T14 13Zm-7 3q-.825 0-1.412-.588Q5 14.825 5 14V6q0-.825.588-1.412Q6.175 4 7 4h14q.825 0 1.413.588Q23 5.175 23 6v8q0 .825-.587 1.412Q21.825 16 21 16Zm-4 4q-.825 0-1.412-.587Q1 18.825 1 18V8q0-.425.288-.713Q1.575 7 2 7t.713.287Q3 7.575 3 8v10h16q.425 0 .712.288q.288.287.288.712t-.288.712Q19.425 20 19 20ZM7 8q.825 0 1.412-.588Q9 6.825 9 6H7Zm14 0V6h-2q0 .825.587 1.412Q20.175 8 21 8ZM7 14h2q0-.825-.588-1.413Q7.825 12 7 12v2Zm12 0h2v-2q-.825 0-1.413.587Q19 13.175 19 14Z" />
            </svg>
            <span class="nav-link">Payments</span>
          </li>
        </router-link>
        <router-link to="/invoices">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 24 24">
              <path fill="currentColor"
                d="M16.247 10.326a1.164 1.164 0 1 1-2.328 0a1.164 1.164 0 0 1 2.328 0zm-6.288 0a1.164 1.164 0 1 1-2.329 0a1.164 1.164 0 0 1 2.329 0zm-.14 13.52c-4.712-.98-8.227-4.257-9.482-8.842c-.421-1.537-.421-4.49 0-6.027C1.506 4.709 4.73 1.485 8.997.316c1.538-.421 4.49-.421 6.028 0c4.267 1.169 7.492 4.393 8.66 8.66c.24.874.294 1.43.294 3.014c0 1.584-.054 2.14-.293 3.014c-1.17 4.271-4.439 7.536-8.661 8.65c-1.391.367-3.916.46-5.206.192zm6.64-9.315c-3.047-1.348-4.054-1.737-4.5-1.737c-.446 0-1.433.38-4.38 1.684c-2.091.926-3.828 1.76-3.86 1.79h16.663zm-9.873-.361c1.621-.729 3.06-1.387 3.196-1.464c.258-.145.337-.09-5.285-3.682c-.56-.358-1.023-.698-1.025-.65v7.19a790.1 790.1 0 0 0 3.114-1.394zm14.078-2.194V8.417c0-.11-1.676.993-3.496 2.12c-3 1.854-3.281 2.06-3.004 2.185c1.345.611 6.42 2.862 6.5 2.872zm-8.169.11c.545.125.643.104 1.226-.263c.349-.22.655-.419.681-.442c.026-.024-.05-.181-.167-.35c-.118-.168-.215-.5-.215-.739V9.86l-.569.21c-.726.267-2.28.27-3 .005l-.556-.205l.013.452c.007.26-.088.563-.225.715c-.232.256-.22.276.45.726c.64.432.725.455 1.23.327a2.349 2.349 0 0 1 1.132-.002zm-4.23-2.65c-.105-.113-2.97-.954-3.033-.891c-.03.03.504.414 1.186.854l1.24.8l.34-.344c.186-.188.307-.377.268-.42zm9.76-.373c.473-.306.8-.555.728-.555c-.155 0-2.877.804-3.027.894c-.057.034.033.229.2.433l.304.37l.47-.293c.257-.162.854-.544 1.326-.85zm-1.636-.555c2.11-.59 3.867-1.102 3.904-1.139H3.59c.187.187 7.779 2.195 8.323 2.202c.41.005 2.014-.376 4.476-1.063z" />
            </svg>
            <span class="nav-link">Invoices</span>
          </li>
        </router-link>
        <router-link to="/referrals">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 48 48">
              <g fill="currentColor">
                <path d="M37 27a3 3 0 1 1-6 0a3 3 0 1 1 6 0Z" />
                <path fill-rule="evenodd"
                  d="M34 25a2 2 0 1 0 0 4a2 2 0 1 0 0-4Zm-4 2a4 4 0 0 1 4-4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4Z"
                  clip-rule="evenodd" />
                <path d="M26 37.364C26 34.462 31.33 33 34 33s8 1.462 8 4.364V42H26v-4.636Z" />
                <path fill-rule="evenodd"
                  d="M28.294 36.867c-.283.285-.294.441-.294.497V40h12v-2.636c0-.056-.01-.212-.294-.497c-.3-.303-.805-.634-1.506-.94C36.788 35.308 35.06 35 34 35s-2.788.31-4.2.926c-.701.306-1.205.638-1.506.941ZM34 33c-2.67 0-8 1.462-8 4.364V42h16v-4.636C42 34.462 36.67 33 34 33Z"
                  clip-rule="evenodd" />
                <path fill-rule="evenodd"
                  d="M15 38c0 .537.18 1.041.497 1.398c.301.339.774.602 1.503.602h10v2H17c-1.271 0-2.298-.487-2.997-1.273C13.32 39.959 13 38.963 13 38v-5h2v5Z"
                  clip-rule="evenodd" />
                <path fill-rule="evenodd"
                  d="m14 30.586l5.707 5.707l-1.414 1.414L14 33.414l-4.293 4.293l-1.414-1.414L14 30.586ZM11 8a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v1h5v19H6V9h5V8Zm0 3H8v2h3v-2Zm0 4H8v11h5v-6h8v6h5V15h-3v1a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-1Zm12-2h3v-2h-3v2Zm-4 13v-4h-4v4h4Zm-3-15V8h2v3h3v2h-3v3h-2v-3h-3v-2h3Z"
                  clip-rule="evenodd" />
              </g>
            </svg>
            <span class="nav-link">Referrals</span>
          </li>
        </router-link>

        <router-link to="/wallet">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 256 256">
              <path fill="currentColor"
                d="M216 40H40a16 16 0 0 0-16 16v136a16 16 0 0 0 16 16h16v16a8 8 0 0 0 16 0v-16h112v16a8 8 0 0 0 16 0v-16h16a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm0 152H40V56h176v64h-16.7a48 48 0 1 0 0 16H216v56Zm-50.2-72a15.8 15.8 0 0 0-13.8-8a16 16 0 0 0 0 32a15.8 15.8 0 0 0 13.8-8H183a32 32 0 1 1 0-16Z" />
            </svg>
            <span class="nav-link">Vault</span>
          </li>
        </router-link>
        <router-link to="/site-report" v-if="isFranchisee">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 256 256">
              <path fill="currentColor"
                d="M216 40H40a16 16 0 0 0-16 16v136a16 16 0 0 0 16 16h16v16a8 8 0 0 0 16 0v-16h112v16a8 8 0 0 0 16 0v-16h16a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16Zm0 152H40V56h176v64h-16.7a48 48 0 1 0 0 16H216v56Zm-50.2-72a15.8 15.8 0 0 0-13.8-8a16 16 0 0 0 0 32a15.8 15.8 0 0 0 13.8-8H183a32 32 0 1 1 0-16Z" />
            </svg>
            <span class="nav-link"> Site Report</span>
          </li>
        </router-link>
        <router-link to="/products">
          <li class="nav-item">
            <svg width="24" height="24" class="mr-3" viewBox="0 0 32 32">
              <path fill="currentColor"
                d="M30 26V14H13v12h5v2H2v2h28v-2h-5v-2zm-5-2v-3h3v3zm-2-3v3h-3v-3zm-3-2v-3h3v3zm8 0h-3v-3h3zm-10-3v3h-3v-3zm-3 8v-3h3v3zm8 4h-3v-2h3zM9.333 14.981A4 4 0 1 1 16 12h-2a2 2 0 1 0-3.333 1.49zM11 2h2v4h-2zm-9 9h4v2H2zm2.222-5.364l1.414-1.414L8.464 7.05L7.05 8.464zM16.95 8.464L15.536 7.05l2.828-2.828l1.414 1.414z" />
            </svg>
            <span class="nav-link"> Devices</span>
          </li>
        </router-link>
        <router-link to="/partners">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 32 32">
              <path fill="currentColor"
                d="M8 9a4 4 0 1 1 4-4a4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2 2 0 0 0-2-2zm16 6a4 4 0 1 1 4-4a4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2a2 2 0 0 0-2-2zm2 27h-4a2 2 0 0 1-2-2v-7h2v7h4v-9h2v-6a1 1 0 0 0-1-1h-6.42L16 20l-4.58-8H5a1 1 0 0 0-1 1v6h2v9h4v-7h2v7a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-7a2 2 0 0 1-2-2v-6a3 3 0 0 1 3-3h7.58L16 16l3.42-6H27a3 3 0 0 1 3 3v6a2 2 0 0 1-2 2v7a2 2 0 0 1-2 2z" />
            </svg>
            <span class="nav-link"> Partners</span>
          </li>
        </router-link>
        <router-link to="/owatts_score/scores">
          <li class="nav-item">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 24 24">
              <g fill="none">
                <path
                  d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path fill="currentColor"
                  d="m12.67 2.217l8.5 4.75A1.5 1.5 0 0 1 22 8.31v1.44c0 .69-.56 1.25-1.25 1.25H20v8h1a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2h1v-8h-.75C2.56 11 2 10.44 2 9.75V8.31c0-.522.27-1.002.706-1.274l8.623-4.819a1.5 1.5 0 0 1 1.342 0ZM18 11H6v8h3v-6h2v6h2v-6h2v6h3v-8Zm-6-6.882l-8 4.5V9h16v-.382l-8-4.5ZM12 6a1 1 0 1 1 0 2a1 1 0 0 1 0-2Z" />
              </g>
            </svg>
            <span class="nav-link"> Owatts Buck</span>
          </li>
        </router-link>
      </template>



      <template v-if="isMerchantDashboard">
        <li class="nav-title text-dark mt-5" style="margin: 2rem !important">My Business</li>
<!-- 
        <router-link to="/merchants/products" v-if="merchant.id">
          <li class="nav-item mb-10">
            <svg width='20' height='20' class="mr-3" viewBox="0 0 24 24">
              <g fill="none">
                <path
                  d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path fill="currentColor"
                  d="m12.67 2.217l8.5 4.75A1.5 1.5 0 0 1 22 8.31v1.44c0 .69-.56 1.25-1.25 1.25H20v8h1a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2h1v-8h-.75C2.56 11 2 10.44 2 9.75V8.31c0-.522.27-1.002.706-1.274l8.623-4.819a1.5 1.5 0 0 1 1.342 0ZM18 11H6v8h3v-6h2v6h2v-6h2v6h3v-8Zm-6-6.882l-8 4.5V9h16v-.382l-8-4.5ZM12 6a1 1 0 1 1 0 2a1 1 0 0 1 0-2Z" />
              </g>
            </svg>
            <span class="nav-link"> My Products</span>
          </li>
        </router-link> -->

        <router-link to="/merchants/sales" v-if="merchant.id || isDistributor">
          <li class="nav-item mb-10">
            <svg width="20" height="20" class="mr-3" viewBox="0 0 48 48">
              <circle cx="13.979" cy="13.016" r="7.516" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" />
              <circle cx="34.021" cy="34.984" r="7.516" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" />
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                d="m10.973 42.5l26.054-37" />
            </svg>
            <span class="nav-link">Sales</span>
          </li>
        </router-link>

        <router-link to="/merchants/customers" v-if="merchant.id || isDistributor" class="mb-10">
          <li class="nav-item mb-10">
            <svg width="20" height="20" class="mr-3" viewBox="0 0 24 24">
              <g fill="none">
                <path
                  d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path fill="currentColor"
                  d="m12.67 2.217l8.5 4.75A1.5 1.5 0 0 1 22 8.31v1.44c0 .69-.56 1.25-1.25 1.25H20v8h1a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2h1v-8h-.75C2.56 11 2 10.44 2 9.75V8.31c0-.522.27-1.002.706-1.274l8.623-4.819a1.5 1.5 0 0 1 1.342 0ZM18 11H6v8h3v-6h2v6h2v-6h2v6h3v-8Zm-6-6.882l-8 4.5V9h16v-.382l-8-4.5ZM12 6a1 1 0 1 1 0 2a1 1 0 0 1 0-2Z" />
              </g>
            </svg>
            <span class="nav-link"> Customers</span>
          </li>
        </router-link>
        <router-link to="/merchants/order" v-if="merchant.id || isDistributor" class="mb-10">
          <li class="nav-item mb-20">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mr-3" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" stroke-width="4"><path stroke-linejoin="round" d="M6 15h36l-2 27H8L6 15Z" clip-rule="evenodd"/><path stroke-linecap="round" stroke-linejoin="round" d="M16 19V6h16v13"/><path stroke-linecap="round" d="M16 34h16"/></g></svg>
            <span class="nav-link"> Buy Products </span>
          </li>
        </router-link>

        
      </template>





    </div>


    <!-- <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    /> -->
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'AppSidebar',
  computed: {
    ...mapState('auth', ['auth']),
    ...mapState(['access_token', 'sidebarVisible']),
    ...mapState('merchants', ['merchant', 'isMerchantDashboard']),
    isDistributor(){
      return Boolean(this.auth.auth.user.is_distributor)
    },


    isFranchisee() {
      return this.auth.auth ? Boolean(this.auth.auth.user.franchisee) : false
    },
  },
  components: {},
  methods: {
    ...mapActions('merchants', ['getMerchant']),
  },

  created() {
    this.getMerchant()
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      auth: computed(() => store.state.auth),
    }
  },
}
</script>

<style>
@import url(@/assets/css/sidenav.css);

.sidebar .nav-group-items a.nav-link {
  position: relative !important;
  left: -2rem !important;
}

.sidebar-nav .mb-10 {
  margin: 0.4rem 0 !important;
}

.sidebar-nav .mb-20 {
  margin-top: 0.4rem !important;
  margin-bottom: 5rem !important;
}
</style>

<style scoped></style>
