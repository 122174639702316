const Reservations = () => import('../../reservations/views/Reservations')
const Reservation = () => import('../views/ReservationDetails')
const EditReservation = () => import('../views/Mentors/EditReservation')
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/reservations',
    name: 'Reservations',
    component: DefaultLayout,
    redirect: '/reservations',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/reservations',
        name: 'Dashboard - Reservations',
        component: Reservations,
      },
      {
        path: '/reservations/:id',
        name: 'Dashboard - Reservation',
        component: Reservation,
      },
      {
        path: '/reservations/edit/:id',
        name: 'Dashboard - Reservation Edit',
        component: EditReservation,
      },
    ],
  },
]
