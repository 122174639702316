const Business = () => import('../views/user/Business')
const Page = () => import('../views/user/Page')

const Profiles = () => import('../views/user/profile/Profiles')
const ProfileDetails = () => import('../views/user/profile/ProfileDetails')
const CreateProfile = () => import('../views/user/profile/CreateProfile')
const EditProfile = () => import('../views/user/profile/EditProfile')

const AdminProfiles = () => import('../views/admin/profile/Profiles')
const AdminProfileDetails = () =>
  import('../views/admin/profile/ProfileDetails')
const AdminCreateProfile = () => import('../views/admin/profile/CreateProfile')
const AdminEditProfile = () => import('../views/admin/profile/EditProfile')

const Projects = () => import('../views/user/project/Projects')
const ProjectDetails = () => import('../views/user/project/ProjectDetails')
const CreateProject = () => import('../views/user/project/CreateProject')
const EditProject = () => import('../views/user/project/EditProject')

const AdminProjects = () => import('../views/admin/project/Projects')
const AdminProjectDetails = () =>
  import('../views/admin/project/ProjectDetails')
const AdminCreateProject = () => import('../views/admin/project/CreateProject')
const AdminEditProject = () => import('../views/admin/project/EditProject')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/business',
    name: 'Business',
    // component: DefaultLayout,
    component: Business,

    redirect: '/:business',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/:business',
        name: 'user.business.index',
        component: Business,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Business Profile',
        },
      },
      {
        path: '/profiles',
        name: 'user.profiles.index',
        component: Profiles,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Profiles',
        },
      },
      {
        path: '/profiles/:id',
        name: 'user.profiles.show',
        component: ProfileDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Profile Details',
        },
      },
      {
        path: '/profiles/create',
        name: 'user.profiles.create',
        component: CreateProfile,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Create Profile',
        },
      },
      {
        path: '/profiles/:id/edit',
        name: 'user.profiles.edit',
        component: EditProfile,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Edit Profile',
        },
      },
      {
        path: '/admin/profiles',
        name: 'admin.profiles.index',
        component: AdminProfiles,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-profile',
          title: 'Profiles',
        },
      },
      {
        path: '/admin/profiles/:id',
        name: 'admin.profiles.show',
        component: AdminProfileDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-profile',
          title: 'Profile Details',
        },
      },
      {
        path: '/admin/profiles/create',
        name: 'admin.profiles.create',
        component: AdminCreateProfile,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Create Profile',
        },
      },
      {
        path: '/admin/profiles/:id/edit',
        name: 'admin.profiles.edit',
        component: AdminEditProfile,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Edit Profile',
        },
      },
      {
        path: '/projects',
        name: 'user.projects.index',
        component: Projects,
        meta: {
          layout: 'default',
          title: 'Projects',
        },
      },
      {
        path: '/projects/:id',
        name: 'user.projects.show',
        component: ProjectDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Project Details',
        },
      },
      {
        path: '/projects/create',
        name: 'user.projects.create',
        component: CreateProject,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Create Project',
        },
      },
      {
        path: '/projects/:id/edit',
        name: 'user.projects.edit',
        component: EditProject,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Edit Project',
        },
      },
      {
        path: '/admin/projects',
        name: 'admin.projects.index',
        component: AdminProjects,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-project',
          title: 'Projects',
        },
      },

      // PROJECT ROUTE
      {
        path: '/admin/projects/:id',
        name: 'admin.projects.show',
        component: AdminProjectDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-project',
          title: 'Project Details',
        },
      },
      {
        path: '/admin/projects/create',
        name: 'admin.projects.create',
        component: AdminCreateProject,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Create Project',
        },
      },
      {
        path: '/admin/projects/:id/edit',
        name: 'admin.projects.edit',
        component: AdminEditProject,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Edit Project',
        },
      },
    ],
  },
]
