import request from '@/services/axios'

export default {
  namespaced: true,
  state: {
    transactions: [],
    payments: '',
    vaultTransactions: {},
    wallet: null,
    vault: {},
    walletReport: null,
  },
  mutations: {
    SET_PAYMENTS(state, data) {
      state.payments = data
    },
    SET_VAULT_TRANSACTIONS(state, data) {
      state.vaultTransactions = data
    },
    SET_PAYMENT(state, data) {
      state.payment = data
    },
    SET_WALLET(state, data) {
      state.wallet = data
    },

    SET_VAULT(state, data) {
      state.vault = data
    },
    SET_WALLET_REPORT(state, data) {
      state.walletReport = data
    },
    addTransaction(state, data) {
      state.transactions.unshift(data)
    },
  },
  actions: {
    getTransactions({ commit }) {
      return new Promise((resolve, reject) => {
        let url = this.getters.isAdminRoute
          ? 'admin/transactions'
          : 'transactions'
        axios.get(url).then((response) => {
          commit('setTransactions', response.data.data)
          resolve(resolve)
        })
      })
    },
    async getPayments({ commit }, params = { page: 1 }) {
      let res = await request().get(`/payments`, { params })
      commit('SET_PAYMENTS', res.data)
      //window.scrollTo(0, 0)
      return res.data
    },

    async getVaultTransactions({ commit }, params = { page: 1 }) {
      let res = await request().get(`/vault/transactions`, { params })
      commit('SET_VAULT_TRANSACTIONS', res.data)
      //window.scrollTo(0, 0)
      return res.data
    },

    async getPayment({ commit }, id) {
      let res = await request().get(`/payments/${id}`, {
        'Content-Type': 'application/json',
      })
      //commit('SET_PAYMENT', res.data)
      //
      return res.data
    },

    async getWalletBal({ commit }, id) {
      let res = await request().get(`/report`, {
        'Content-Type': 'application/json',
      })
      commit('SET_WALLET', res.data.data)
      return res.data.data
    },
    async getWalletReport({ commit }, id) {
      let res = await request().get(`/wallet/balance`, {
        'Content-Type': 'application/json',
      })
      commit('SET_WALLET_REPORT', res.data.data)
      return res.data.data
    },

    async getVault({ commit }, id) {
      let res = await request().get(`/vault/balance`, {
        'Content-Type': 'application/json',
      })
      commit('SET_VAULT', res.data.data)

      return res.data.data
    },

    async updateVault({ commit, dispatch }, data) {
      let res = await request().post(`/vault/update`, data)
      dispatch('getVault')
      return res.data.data
    },

    async transferVault({ commit, dispatch }, data) {
      let res = await request().post(`/vault/transfer`, data)
      dispatch('getVault')
      dispatch('getWalletBal')
      dispatch('getWalletReport')
      return res.data.data
    },

    async loadVault({ commit, dispatch }, data) {
      let res = await request().post(`/payments/vault`, data)
      dispatch('getVault')
      return res.data.data
    },

    async createPaymentLink({ commit, rootState, state }, payload) {
      try {
        const res = await request().post(`payments/links/${payload.txntype}`, payload, {})
        return res.data.data
      } catch (error) {
        throw error.response.data
      }
    },

  },
  getters: {
    transactions: (state) => {
      return state.transactions
    },
  },
}
