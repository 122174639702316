<template>
  <div class="w-100" v-if="(merchant && Object.keys(merchant).length || isDistributor) && showSwitch">
    <!-- <div class="d-md-flex gap-5 align-items-center">
        <h6 class="font-bold">My Business Dashboard</h6>
        <label class="toggle-button">
            <input type="checkbox" v-model="isMerchantDashboard"  @change="toggleMerchantDashboard" />
            <span class="toggle-slider round"></span>
        </label>
    </div> -->

    <button class="btn btn-save btn-sm" type="button" @click="toggleMerchantDashboard">
      Switch to  {{isMerchantDashboard ? 'User Dashboard' : 'Business Dashboard'}} 
    </button>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Dashboard Toggle',
  computed: {
    ...mapState('merchants', ['merchant', 'isMerchantDashboard']),
    ...mapState('auth', ['auth']),
    isDistributor(){
      return Boolean(this.auth.user.is_distributor)
    },
    showSwitch() {
      return this.$route.meta && this.$route.meta.showDashboardSwitch
    }
  },
  methods: {
    ...mapActions('merchants', ['getMerchant', 'toggleMerchantDashboard']),
  },
  components: {},

  watch: {
    isMerchantDashboard: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.$router.push('/merchants/products')
        }
        else {
          this.$router.push('/dashboard/overview')
        }
      },
      deep: true
    }
  },
  created() {
    this.getMerchant()
  },
}
</script>

<style scoped>
.toggle-button {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}

.toggle-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: -1px;
  bottom: 1px;
  background-color: #dfdad2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid black;
}

input:checked+.toggle-slider {
  background-color: #479658;
}

input:focus+.toggle-slider {
  box-shadow: 0 0 1px black;
}

input:checked+.toggle-slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.toggle-slider.round {
  border-radius: 44px;
  border: 1px solid black;
}

.toggle-slider.round:before {
  border-radius: 50%;
}
</style>