const LoginPage = () => import('../views/Login')
const Register = () => import('../views/Register')
const ResetPassword = () => import('../views/ResetPassword')
const ChangePassword = () => import('../views/ChangePassword')
const Privacy = () => import('../views/Privacy')
const Join = () => import('../views/JoinUs')
const Contact = () => import('../views/contact/ContactUs')
const FAQ = () => import('../views/faq/faq')
const Policy = () => import('../views/terms/Policy')
const LegalTerms = () => import('../views/terms/LegalTerms')
const Warranty = () => import('../views/terms/Warranty')
const Profile = () => import('../views/profile/Profile')
const Error404 = () => import('../views/Page404')
const Error500 = () => import('../views/Page500')
import store from '@/store/index'

export default [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { layout: 'default' },
    async beforeEnter(to, from, next) {
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next({
            path: '/dashboard', // back to dashboard route //
          })
        } else {
          next()
        }
      } catch (e) {
        next({
          path: '/dashboard', // back to dashboard route //
        })
      }
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { layout: 'default' },
    async beforeEnter(to, from, next) {
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next({
            path: '/dashboard', // back to dashboard route //
          })
        } else {
          next()
        }
      } catch (e) {
        next({
          path: '/dashboard', // back to dashboard route //
        })
      }
    },
  },
  {
    path: '/r/:uid',
    name: 'Register with Referral Link',
    component: Register,
    meta: { layout: 'default' },
    async beforeEnter(to, from, next) {
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next({
            path: '/dashboard', // back to dashboard route //
          })
        } else {
          next()
        }
      } catch (e) {
        next({
          path: '/dashboard', // back to dashboard route //
        })
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'Reset Password',
    component: ResetPassword,
    meta: { layout: 'default' },
    async beforeEnter(to, from, next) {
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next({
            path: '/dashboard', // back to dashboard route //
          })
        } else {
          next()
        }
      } catch (e) {
        next({
          path: '/dashboard', // back to dashboard route //
        })
      }
    },
  },
  {
    path: '/reset-password/:token',
    name: 'Change Password',
    component: ChangePassword,
    meta: { layout: 'default' },
  },
  {
    path: '/join-the-solution',
    name: 'Join Us',
    component: Join,
    meta: { layout: 'default' },
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: Contact,
    meta: { layout: 'default' },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: { layout: 'default' },
  },
  {
    path: '/terms',
    name: 'Policy',
    component: Policy,
    meta: { layout: 'default' },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { layout: 'default' },
  },
  {
    path: '/legal_terms',
    name: 'Legal Terms',
    component: LegalTerms,
    meta: { layout: 'default' },
  },
  {
    path: '/warranty',
    name: 'Warranty',
    component: Warranty,
    meta: { layout: 'default' },
  },
  {
    path: '/profile/:username',
    name: 'User - Profile',
    component: Profile,
    meta: { layout: 'default' },
  },
  {
    path: '/404',
    name: 'Eror404',
    component: Error404,
    meta: { layout: 'default', auth: true, title: 'Error404' },
  },
  {
    path: '/500',
    name: 'Eror500',
    component: Error500,
    meta: { layout: 'default', auth: true, title: 'Error500' },
  },
]
