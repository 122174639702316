<template>
  <div
    class="d-md-flex justify-content-between mt-4 paginationComponent"
    v-if="meta"
  >
    <span class="mr-2"
      >{{ meta.from }} - {{ meta.to }} of {{ meta.total }}</span
    >
    <span>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li
          v-for="link in meta.links"
          :key="link"
          @click="
            ;(params = {
              ...params,
              page: link.label.includes('Next')
                ? meta.current_page + 1
                : link.label.includes('Previous')
                ? meta.current_page - 1
                : link.label,
            }),
              action(params)
          "
          :disabled="meta.to == meta.total"
        >
          <span
          class="page-link"
            v-html="link.label"
            :class="link.active ? 'text-warning font-weight-bold' : ''"
          ></span>
        </li>

        </ul>
      </nav>
    </span>
  </div>
</template>

<script>
export default {
  props: ['meta', 'params', 'action'],
  methods: {
    scrollToTop() {
      //window.scrollTo(0, 0)
    },
  },
}
</script>
<style>
.paginationComponent .page-link {
  background-color: inherit !important;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
<style scoped>
span {
  color: #000;
}
button {
  height: 30px;
  width: 30px;
  align-items: center;
  border-radius: 50%;
  padding: 0.366rem;
  background-color: transparent;
  border: solid 1px #efefef;
}
/* button:not(:disabled) {
  border: solid 1px #efefef;
} */
button:hover {
  background-color: #efefef;
}
button:not(:disabled) > i {
  color: inherit;
}
button:hover > i {
  color: inherit;
}

.bg-success {
  background-color: #28a745 !important;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}
.pagination .page-link{
  position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>
