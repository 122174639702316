// Site Report
const SiteReport = () => import('../views/user/Report.vue')

// User
const Questionnairas = () =>
  import('../views/user/questionnaira/Questionnairas')
const QuestionnairaDetails = () =>
  import('../views/user/questionnaira/QuestionnairaDetails')
const Details = () => import('../views/user/questionnaira/QuestionaireDetails')
const CreateQuestionnaira = () =>
  import('../views/user/questionnaira/CreateQuestionnaira')

const AdminQuestionnairas = () =>
  import('../views/admin/questionnaira/Questionnairas')
const AdminQuestionnairaDetails = () =>
  import('../views/admin/questionnaira/QuestionnairaDetails')
const AdminCreateQuestionnaira = () =>
  import('../views/admin/questionnaira/CreateQuestionnaira')
const AdminEditQuestionnaira = () =>
  import('../views/admin/questionnaira/EditQuestionnaira')

//Assessment
const Assessment = () => import('../views/user/assessment/Assessment')
const AssessmentDetails = () =>
  import('../views/user/assessment/AssessmentDetails')
const CreateAssessment = () =>
  import('../views/user/assessment/CreateAssessment')

const AdminAssessment = () => import('../views/admin/assessment/Assessment')
const AdminAssessmentDetails = () =>
  import('../views/admin/assessment/AssessmentDetails')
const AdminCreateAssessment = () =>
  import('../views/admin/assessment/CreateAssessment')
const AdminEditAssessment = () =>
  import('../views/admin/assessment/EditAssessment')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  // User
  {
    path: '/report',
    name: 'Report',
    component: DefaultLayout,
    redirect: '/site-report',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/site-report',
        name: 'user.questionaires.report',
        component: SiteReport,
        meta: {
          layout: 'DefaultLayout',
          auth: true,
          guard: 'user',
          title: 'Site Report',
        },
      },
      {
        path: '/questionaires',
        name: 'user.questionaires.index',
        component: Questionnairas,
        meta: {
          layout: 'DefaultLayout',
          auth: true,
          guard: 'user',
          permission: 'view-questionnaira',
          title: 'Questionnairas',
        },
      },
      {
        path: '/questionaires/certificate/:id',
        name: 'user.questionaires.show',
        component: QuestionnairaDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          permission: 'view-questionnaira',
          title: 'Questionnaira Details',
        },
      },
      {
        path: '/questionaires/:tag',
        name: 'user.questionaires.tag',
        component: Details,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
        },
      },
      {
        path: '/questionaires/create',
        name: 'user.questionaires.create',
        component: CreateQuestionnaira,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Create Questionnaira',
        },
      },

      //Assessment
      {
        path: '/assessments',
        name: 'user.assessments.index',
        component: Assessment,
      },
      {
        path: '/assessments/:id',
        name: 'user.assessments.show',
        component: AssessmentDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          permission: 'view-assessment',
          title: 'Assessment Details',
        },
      },
      {
        path: '/assessments/create',
        name: 'user.assessments.create',
        component: CreateAssessment,
        async beforeEnter(to, from, next) {
          try {
            var hasPermission = await store.getters.get_access_token
            if (hasPermission) {
              next()
            } else {
              next({
                name: 'Login', // back to safety route //
                query: { redirectFrom: to.fullPath },
              })
            }
          } catch (e) {
            next({
              name: 'Login', // back to safety route //
              query: { redirectFrom: to.fullPath },
            })
          }
        },
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Create Assessment',
        },
      },

      // Admin

      {
        path: '/admin/questionaires',
        name: 'admin.questionaires.index',
        component: AdminQuestionnairas,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-questionnaira',
          title: 'Questionnairas',
        },
      },

      {
        path: '/admin/questionaires/:id',
        name: 'admin.questionaires.show',
        component: AdminQuestionnairaDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-questionnaira',
          title: 'Questionnaira Details',
        },
      },
      {
        path: '/admin/questionaires/create',
        name: 'admin.questionaires.create',
        component: AdminCreateQuestionnaira,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Create Questionnaira',
        },
      },
      {
        path: '/admin/questionaires/:id/edit',
        name: 'admin.questionaires.edit',
        component: AdminEditQuestionnaira,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Edit Questionnaira',
        },
      },

      //Admin Assessment
      {
        path: '/admin/assessments',
        name: 'admin.assessments.index',
        component: AdminAssessment,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-assessment',
          title: 'Assessment',
        },
      },

      {
        path: '/admin/assessments/:id',
        name: 'admin.assessments.show',
        component: AdminAssessmentDetails,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          permission: 'view-assessment',
          title: 'Assessment Details',
        },
      },
      {
        path: '/admin/assessments/create',
        name: 'admin.assessments.create',
        component: AdminCreateAssessment,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Create Assessment',
        },
      },
      {
        path: '/admin/assessments/:id/edit',
        name: 'admin.assessments.edit',
        component: AdminEditAssessment,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'admin',
          title: 'Edit Assessment',
        },
      },
    ],
  },
]
