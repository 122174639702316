// Import request/axios config
import request from '@/services/lifestyle/axios'
import moshaToast from 'mosha-vue-toastify'

import 'mosha-vue-toastify/dist/style.css'

export default {
  namespaced: true,
  state: {
    reservations: null,
    reservation: null,
    owattsScore: null,
    paymentScore: null,
    gb_payments: [],
    mentor_reservation: null,
    scores: null,
    score: null,
    bucks: null,
    buck_details: null,
  },

  // Mutations
  mutations: {
    SET_RESERVATIONS(state, data) {
      state.reservations = data.data
      // console.log(state.reservations)
    },
    SET_RESERVATION(state, data) {
      state.reservation = data.data
    },
    MENTOR_RESERVATION(state, data) {
      state.mentor_reservation = data
    },
    SET_OWATTS_SCORE(state, data) {
      state.owattsScore = data
      delete state.owattsScore.StatDetails
    },
    PAYMENT_SCORE(state, data) {
      state.paymentScore = data
    },
    SET_SCORES(state, data) {
      state.scores = data.data
    },
    SET_SCORE(state, data) {
      state.score = data.data
    },
    SET_BUCKS(state, data) {
      state.bucks = data.data
    },
    SET_BUCK_DETAILS(state, data) {
      state.buck_details = data.data
    },
    GB_PAYMENT(state, data) {
      // console.log(typeof state.gb_payments)
      state.gb_payments.push(data)
      // console.log(state.gb_payments)
    },
  },

  // Actions
  actions: {
    // Get Reservations request

    async getReservations({ commit }) {
      let res = await request().get(`/reservations`, {
        'Content-Type': 'application/json',
      })
      console.log(res);
      commit('SET_RESERVATIONS', res.data)

      return res
    },

    // Get Reservation request

    async getReservation({ commit }, id) {
      let res = await request().get(`/reservations/${id}`, {
        'Content-Type': 'application/json',
      })
      commit('SET_RESERVATION', res.data)

      return res.data.data
    },

    // PAY APPROVAL FEE
    async payApprovalFee({ commit }, data) {
      let res = await request().post(`/payments/approvalfee`, data, {
        'Content-Type': 'application/json',
      })
      commit('APPROVAL_FEE', res.data)

      return res.data.data || res
    },

    // GET MENTOR's RESERVATIONS
    async getAssignedReservations({ commit }, data) {
      let res = await request().get(`mentor/reservations`, data, {
        'Content-Type': 'application/json',
      })
      commit('MENTOR_RESERVATION', res.data)

      return res.data.data
    },
    // CONFIRM PAYMENT
    async confirmPayment({ commit }, data) {
      let res = await request().get(
        `payment/transaction?txnref=${data}`,
        data,
        {
          'Content-Type': 'application/json',
        },
      )
      commit('GB_PAYMENT', res.data)

      return res.data.data
    },
    // PAY ORDER FEE
    async payOrderFee({ commit }, data) {
      let res = await request().post(`/payments/order`, data, {
        'Content-Type': 'application/json',
      })
      commit('ORDER_FEE', res.data)

      return res.data.data
    },

    // MENTOR UPDATE RESERVATION
    async updateReservation({ commit }, data) {
      let res = await request().patch(`/reservations/${data.id}`, data, {
        'Content-Type': 'application/json',
      })

      return res.data.data
    },

    // PAY OWATTS SCORE FEE
    async payOwattsScoreFee({ commit }, data) {
      let res = await request().post(`/payments`, data, {
        'Content-Type': 'application/json',
      })
      commit('OWATTS_SCORE_FEE', res.data)

      return res.data.data
    },

    // PAY OWATTS SCORE FEE
    async postPaymentScore({ commit }, data) {
      // console.log('data')
      let res = await request().post(`/payment/scores`, data, {
        'Content-Type': 'application/json',
      })
      commit('PAYMENT_SCORE', res.data)

      return res.data.data
    },

    // GET SCORES (UAT)
    async getScores({ commit }) {
      let res = await request().get(`/payment/scores`, {
        'Content-Type': 'application/json',
      })
      commit('SET_SCORES', res.data)

      return res.data.data
    },
    // GET SCORE (UAT)
    async getScore({ commit }, id) {
      let res = await request().get(`/payment/scores/${id}`, {
        'Content-Type': 'application/json',
      })
      commit('SET_SCORE', res.data)

      return res.data.data
    },

    // BUCK
    async createBuck({ commit, dispatch }, payload) {
      console.log(payload)
      let res = await request().post(`/bucks`, payload, {
        'Content-Type': 'application/json',
      })
      dispatch('getBucketScores')
      return res
    },
    async getBucketScores({ commit }) {
      let res = await request().get(`/bucks`, {
        'Content-Type': 'application/json',
      })
      commit('SET_BUCKS', res.data)

      return res.data.data
    },
    async findBucketScores({ commit }, id) {
      let res = await request().get(`/bucks/${id}`, {
        'Content-Type': 'application/json',
      })
      commit('SET_BUCK_DETAILS', res.data)

      return res
    },
  },

  // Getters
  getters: {
    getReservation: (state) => (id) => {
      // console.log(id)
      // console.log(state.reservations)
      let product = state.reservations.find((product) => product.id == id)
      // console.log(product)
      return product
    },
  },
}
