<template>
  <div class="container-fluid" v-if="!currentRoutePath">
    <form class="my-0">
      <div class="row height d-flex justify-content-end align-items-center">
        <div class="col-md-6 pe-0">
          <div class="form">
            <div class="d-flex flex-column flex-wrap align-items-end">
              <div
                class="font-weight-bold text-center"
                v-if="$route.name.includes('merchants')"
              >
                MERCHANT LINK: {{ auth ? auth.user.uid : '' }}
              </div>
              <div class="font-weight-bold text-center" v-else>
                USER ID: {{ auth ? auth.user.uid : '' }}
              </div>

              <!-- <h6 class="mb-0 font-weight-bold">REFERRAL LINK:</h6> -->
              <!-- <div
                @click.stop.prevent="copyTestingCode"
                class="btn btn-save"
                style="width: max-content"
              >
                Copy Here
              </div> -->
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <!-- <h6 class="font-weight-bold">REFERRAL CODE</h6> -->
                <div>
                  <div
                    class="border cursor-pointer border-gray-800 rounded mb-auto text-sm copy-btn px-3 py-1"
                    @click.stop.prevent="copyTestingCode"
                  >
                    Copy
                    {{
                      $route.name.includes('merchants')
                        ? 'Merchant'
                        : 'Referral'
                    }}
                    Link
                  </div>
                </div>
              </div>
              <div style="opacity: 0.1; z-index: -1">
                <div
                  class="form-control wizard-form-control d-flex align-items-center testing-code px-20px mb-10px"
                >
                  <input
                    type="hidden"
                    id="testing-code"
                    :value="Url"
                    placeholder="Referral Code"
                  />
                </div>
              </div>
              <!-- <h6 class="font-weight-bold">{{ auth.user.profile_url }}</h6> -->
            </div>
            <!-- <i class="fa fa-search"></i>
            <input
              type="text"
              class="form-control form-input"
              placeholder="Search anything..."
            />
            <span class="left-pan"><i class="fa fa-microphone"></i></span> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      Url: '',
      mytext: 'jhjhb',
      link_url: 'uiguig',
      testingCode: '1234',
      referrer_link: '',
    }
  },
  computed: {
    ...mapState('auth', ['auth']),
    ...mapState('merchants', ['merchant']),
    currentRoutePath(){
      return this.$route.fullPath.includes('user') || this.$route.fullPath.includes('kike')  || this.$route.fullPath.includes('products/k')
    }
  },
  methods: {
    ...mapActions('merchants', ['getMerchant']),
    copyTestingCode() {
      this.referrer_link =
        this.$config.appUrl + 'register?referrer=' + this.auth.user.uid

      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        this.makeToast(`Copied`, `${this.Url}`, 'success')
      } catch (err) {
        alert('Oops, unable to copy')
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },

  created() {
    this.getMerchant()
    this.$route.name.includes('merchants')
      ? (this.Url = `${this.$config.appUrl}@${this.auth.user.uid}`)
      : // (this.Url = `${this.$config.appUrl}marketplace/merchant/${this.merchant.id}`)
        (this.Url = `${this.$config.appUrl}@${this.auth.user.uid}`)
  },
}
</script>

<style scoped>
.form {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin: 0 !important;
}

.form .fa-search {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #9ca3af;
}

.form span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.left-pan {
  padding-left: 7px;
}

.left-pan i {
  padding-left: 10px;
}

.form-input {
  height: 50px;
  text-indent: 33px;
  border-radius: 10px;
  font-size: small;
}

.form-input:focus {
  box-shadow: none;
  border: none;
}

@media (min-width: 768px) {
  .form {
    width: max-content;
    margin-left: auto !important;
  }
}
</style>
