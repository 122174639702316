<template>
  <div>
    <div class="subheader_daterange" id="subheader_daterange">
      <span class="subheader-daterange-label">
        <span class="subheader-daterange-title"
          ><span v-if="!startDate">Select date:</span></span
        >
        <span class="subheader-daterange-date"></span>
      </span>
      <button class="btn btn-floating btn-sm rounded-0" type="button">
        <i class="ft-calendar"></i>
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
// import daterangepicker from '@daterangepicker'

export default {
  props: ['start', 'end'],
  props: {
    start: {
      type: String,
      description: 'Start date',
    },
    end: {
      type: String,
      description: 'End date',
    },
  },
  computed: {
    startDate() {
      if (!this) return
      return this.$route.query.startDate || this.start
    },
    endDate() {
      if (!this) return
      return this.$route.query.endDate || this.end
    },
  },
  mounted() {
    // alert(this.$route.query.startDate);
    // let dr = $('#subheader_daterange');
    // if (dr.length) {
    // let start = this.startDate ? dayjs(this.startDate) : dayjs('2020-01-01');
    // let end = this.endDate ? dayjs(this.endDate) : dayjs;
    let start = this.dayjs(this.startDate)
    let end = this.dayjs(this.endDate)

    $('#subheader_daterange').daterangepicker(
      {
        startDate: start,
        endDate: end,
        ranges: {
          Today: [this.dayjs(), this.dayjs()],
          Yesterday: [
            this.dayjs().subtract(1, 'days'),
            this.dayjs().subtract(1, 'days'),
          ],
          'Last 7 Days': [this.dayjs().subtract(6, 'days'), this.dayjs()],
          'Last 30 Days': [this.dayjs().subtract(29, 'days'), this.dayjs()],
          'This Month': [
            this.dayjs().startOf('month'),
            this.dayjs().endOf('month'),
          ],
          'Last Month': [
            this.dayjs().subtract(1, 'month').startOf('month'),
            this.dayjs().subtract(1, 'month').endOf('month'),
          ],
          // 'Clear': [null, this.dayjs]
        },
      },
      this.f,
    )

    if (this.startDate) {
      this.f(start, end, '')
    }
    // }
  },
  methods: {
    f(start, end, label) {
      this.$emit('filtered', {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
      })

      let o = '',
        n = ''
      end - start < 100 || 'Today' == label
        ? ((o = 'Today:'), (n = start.format('MMM D')))
        : // start == null || start === 'Invalid date' || "Clear" == label ? o = 'Select date:' :
        'Yesterday' == label
        ? ((o = 'Yesterday:'), (n = start.format('MMM D')))
        : (n = start.format('MMM D') + ' - ' + end.format('MMM D')),
        $('#subheader_daterange').find('.subheader-daterange-date').html(n),
        $('#subheader_daterange').find('.subheader-daterange-title').html(o)
    },
  },
}
</script>
