<template>
  <div class="d-flex justify-content-between align-items-center mb-0">
    <div
      @click="this.$router.go(-1)"
      class="flex cursor-pointer align-items-center"
    >
      <img src="@/assets/images/arrow_back.png" alt="" class="mr-3 w-3 h-3" />
      <span>Back</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
