const Dashboard = () => import('../views/Dashboard')
const Overview = () => import('../views/Overview')

const Settings = () => import('../views/settings/Settings')
const Partners = () => import('../views/partners/Partner')

const Installers = () => import('../views/partners/installers/CreateInstaller')
const InstallerDetails = () =>
  import('../views/partners/installers/InstallerDetails')
const EditInstaller = () => import('../views/partners/installers/EditInstaller')

const Institutes = () => import('../views/partners/institutes/CreateInstitute')
const EditInstitutes = () =>
  import('../views/partners/institutes/EditInstitute')
const InstituteDetails = () =>
  import('../views/partners/institutes/InstituteDetails')
const Insurances = () => import('../views/partners/insurances/CreateInsurance')
const Wallets = () => import('../views/wallet/Wallets')
const Payments = () => import('../../payment/views/SelectPaymentMode')
const PayLater = () => import('../../payment/views/PayLater')
const RequstTicket = () => import('../../payment/views/Specta/RequestTicket')
const ScoreResult = () => import('../../payment/views/Specta/ScoreResult')
// const RunScore = () => import('../../payment/views/Specta/Steps')
const OwattsScores = () =>
  import('../../payment/views/Specta/PaymemtScoreTable')
const BuckDetails = () => import('../../payment/views/Specta/BuckDetails')
const OwattsScoreDetails = () =>
  import('../../payment/views/Specta/PaymentScoreDetails')

const Franchisee = () =>
  import('../views/partners/franchisees/CreateFranchisee')
const FranchiseeDetails = () =>
  import('../views/partners/franchisees/FranchiseeDetails')
const EditFranchisee = () =>
  import('../views/partners/franchisees/EditFranchisee')

const Products = () => import('../views/products/Index')
const KikeAnalytics = () => import('../views/products/Analytics/Kike')
const ChiomaProduct = () => import('../views/products/Chioma')
const KikeProduct = () => import('../views/products/Kike')
const AutoGenProduct = () => import('../views/products/AutoGen')
const DeviceDetails = () => import('../views/products/DeviceDetails')

const Profile = () => import('../views/profile/Profile')
const IFASystemDesign = () => import('../views/IFA/SystemDesign')

const RealtimeDetails = () =>
  import('../views/products/RealTime/RealTimeDetails')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'
import config from '@/config/'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DefaultLayout,
    redirect: '/dashboard/user',
    async beforeEnter(to, from, next) {
      // $('.modal').modal('hide')
      $('body').removeClass('modal-open')
      $('.modal-backdrop').remove()
      if (screen.width <= 768) {
        // alert(90)
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }

      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },

    children: [
      {
        path: '/dashboard/overview',
        name: 'Dashboard - Overview',
        component: Overview,
        meta:{showDashboardSwitch: true}
      },
      {
        path: '/dashboard/user',
        name: 'Dashboard - User',
        component: Dashboard,
      },

      {
        path: '/settings',
        component: Settings,
      },
      {
        path: '/partners',
        name: 'Dashboard - Partners',
        component: Partners,
      },
      {
        path: '/partners/create-franchisee',
        name: 'Dashboard - Franchisee',
        component: Franchisee,
      },
      {
        path: '/partners/franchisee',
        name: 'user.franchisee.show',
        component: FranchiseeDetails,
        meta: {
          auth: true,
          title: 'Franchisee Details',
        },
      },
      {
        path: '/partners/franchisee/edit',
        name: 'user.franchisee.edit',
        component: EditFranchisee,
        meta: {
          auth: true,
          title: 'Edit Franchisee ',
        },
      },
      {
        path: '/partners/installers',
        name: 'Dashboard - Installers',
        component: Installers,
      },
      {
        path: '/partners/installer',
        name: 'user.installer.show',
        component: InstallerDetails,
        meta: {
          auth: true,
          title: 'Installer Details',
        },
      },
      {
        path: '/partners/installer/edit',
        name: 'user.installer.edit',
        component: EditInstaller,
        meta: {
          auth: true,
          title: 'Edit Installer ',
        },
      },
      {
        path: '/partners/institutes',
        name: 'Dashboard - Institutes',
        component: Institutes,
      },
      {
        path: '/partners/institutes/:id',
        name: 'Dashboard - Institute Details',
        component: InstituteDetails,
      },
      {
        path: '/partners/institutes/edit',
        name: 'Dashboard - Institute Edit',
        component: EditInstitutes,
      },
      {
        path: '/partners/insurance',
        name: 'Dashboard - Insurances',
        component: Insurances,
      },
      {
        path: '/wallet',
        name: 'Dashboard - Wallets',
        component: Wallets,
      },
      {
        path: '/payment',
        name: 'Dashboard - Payment',
        component: Payments,
      },
      {
        path: '/payment/later',
        name: 'Dashboard - Pay Later',
        component: PayLater,
      },
      {
        path: '/payment/request-ticket',
        name: 'Dashboard - Request Ticket',
        component: RequstTicket,
      },
      // {
      //   path: '/owatts_score/run',
      //   name: 'Dashboard - Owatts Score Run',
      //   component: RunScore,
      // },
      {
        path: '/owatts_score/result',
        name: 'Dashboard - Owatts Score Result',
        component: ScoreResult,
      },
      {
        path: '/owatts_score/scores',
        name: 'Dashboard - Owatts Score Table',
        component: OwattsScores,
      },
      {
        path: '/bucks/:id',
        name: 'Dashboard - Owatts Buck Details',
        component: BuckDetails,
      },
      {
        path: '/owatts_score/score/:id',
        name: 'Dashboard - Owatts Score Details',
        component: OwattsScoreDetails,
      },
      {
        path: '/profile',
        name: 'Dashboard - User Profile',
        component: Profile,
      },
      {
        path: '/system_design',
        name: 'Dashboard - IFA System Design',
        component: IFASystemDesign,
      },

      // {
      //   path: '/obidder',
      //   name: 'Obidder - Devices',
      //   component: Devices,
      // },
      // {
      //   path: '/obidder/:id',
      //   name: 'Obidder - Device',
      //   component: Device,
      // },
      // {
      //   path: '/obidder/create-fee',
      //   name: 'Obidder - Create Energy Fee',
      //   component: CreateEnergyFee,
      // },
      // {
      //   path: '/obidder/users/:id',
      //   name: 'Obidder - Estate Users',
      //   component: EstateUsers,
      // },

      {
        path: '/device/details/:id',
        name: 'Dashboard - Device Details',
        component: DeviceDetails,
      },
      {
        path: '/products',
        name: 'Dashboard - Products',
        component: Products,
      },
      {
        path: '/products/c/:imei',
        name: 'user.products.chioma',
        component: ChiomaProduct,
      },
      {
        path: '/products/k/:imei',
        name: 'user.products.kike',
        component: KikeProduct,
      },
      {
        path: '/products/k/analytics/:imei',
        name: 'user.products.analytics.kike',
        component: KikeAnalytics,
      },
      {
        path: '/products/autogen/:imei',
        name: 'user.products.autogen',
        component: AutoGenProduct,
      },
      {
        path: '/products/:imei/details',
        name: 'user.products.details',
        component: RealtimeDetails,
      },
    ],
  },
]
