// Import request/axios config
import request from '@/services/lifestyle/axios'

export default {
  namespaced: true,
  state: {
    reservations: null,
    reservation: null,
  },

  // Mutations
  mutations: {
    SET_RESERVATIONS(state, data) {
      state.reservations = data.data
    },
    SET_RESERVATION(state, data) {
      state.reservation = data
    },
  },

  // Actions
  actions: {
    // FRANCHISEE

    // Add Franchisee
    async addFranchisee({ commit }, payload) {
      // console.log(payload)
      const res = await request().post('/franchisees', payload.franchisee, {
        'Content-Type': 'application/json',
      })
      commit('FRANCHISEE', res.data.data)
      return res
    },

    // Get Franchisee Data

    async getFranchisee({ commit }) {
      const res = await request().get('/franchisees', {
        'Content-Type': 'application/json',
      })
      commit('FRANCHISEE', res.data.data)

      return res.data.data
    },

    // Update Franchisee
    async updateFranchisee({ commit }, payload) {
      // console.log(payload)
      const res = await request().patch('/franchisees/' + payload.id, payload, {
        'Content-Type': 'application/json',
      })
      commit('UPDATE', res.data.data)
      return res.data.data
    },

    // Delete Franchisee
    async deleteFranchisee({ commit }, payload) {
      // console.log(payload)
      const res = await request().delete(
        '/franchisees/' + payload.id,
        payload,
        {
          'Content-Type': 'application/json',
        },
      )
      commit('DELETE_FRANCHISEE', res.data.data)
      return res.data.data
    },
    // FRANCHISEE

    // INSTALLERS

    // Add Installer
    async addInstaller({ commit }, payload) {
      // console.log(payload)
      const res = await request().post('/installers', payload.installer)
      commit('ADD_INSTALLER', res.data.data)

      return res
    },

    // Get Installer
    async getInstaller({ commit }, payload) {
      // console.log(payload)
      const res = await request().get('/installers/' + payload, {})
      commit('INSTALLER', res.data.data)

      return res.data.data
    },

    // Update Installer
    async updateInstaller({ commit }, payload) {
      // console.log(payload)

      const res = await request().patch(
        'installer/' + payload.id,
        payload.franchisee,
      )
      commit('UPDATE', res.data.data)

      return res.data.data
    },

    // INSTALLERS

    // INSTITUTES

    // Add Institute
    async addInstitute({ commit }, payload) {
      // console.log(payload)
      const res = await request().post('/institutes', payload)
      commit('ADD_INSTITUTE', res.data)

      return res
    },
    async updateInstitute({ commit }, payload) {
      // console.log(payload)
      const res = await request().patch('/institutes/' + payload.id, payload, {
        'Content-Type': 'application/json',
      })
      commit('UPDATE_INSTITUTE', res.data.data)
      return res.data.data
    },
    async getInstitutes({ commit }) {
      const res = await request().get('/institutes', {
        'Content-Type': 'application/json',
      })
      commit('INSTITUTES', res.data.data)

      return res.data.data
    },
    // Get Installer
    async getInstitute({ commit }, payload) {
      // console.log(payload)
      const res = await request().get('/institutes/' + payload)
      commit('INSTITUTE', res.data.data)

      return res.data.data
    },

    // INSTITUTES

    // INSURANCE
    // Add Insurance
    async addInsurance({ commit }, payload) {
      // console.log(payload)
      const res = await request().post('/insurances', payload)
      commit('ADD_INSURANCE', res.data)

      return res
    },

    // INSURANCE
  },

  // Getters
  getters: {
    getReservation: (state) => (id) => {
      // console.log(id)
      // console.log(state.reservations)
      let product = state.reservations.find((product) => product.id == id)
      // console.log(product)
      return product
    },
  },
}
