// Import axios/request config
import request from '@/services/lifestyle/axios'

export default {
  namespaced: true,
  state: {
    step: 1,
    options: [],
    product_categories: [],
    products: [],
    accessory: [],
    accessories: [],
    options_data: null,
    cart_items: null,
    selectedProduct: '',
    exchangeRates: {},
    cart: [],
    order: {
      products: [],
      currency: 'USD',
      little: null,
      merchant_id: null,
      reservation_fee: 0
    },
    swapFormCurrentState:1,
    swapForm:{
      one: true,
      two: false,
      three: false
    },

    swapDetails:{
      // battery_state : null
    }
  },

  // Mutations
  mutations: {
    PRODUCTS(state, data) {
      state.products = data
    },
    OPTIONS(state, data) {
      state.product_categories = data
    },
    OPTIONS_DATA(state, data) {
      state.options_data = data
    },
    CART_ITEMS(state, data) {
      state.cart_items = data
    },
    ADD_TO_CART(state, data) {
      state.cart_items = data
    },
    SET_ACCESSORIES(state, data) {
      state.accessories = data
    },
    SET_SELECTED_PRODUCT(state, data) {
      state.selectedProduct = data
    },
    SET_ORDER_PID(state, data) {
      state.order.pid = data
      
    },

    // Add Item to cart
    ADD_ITEM_TO_CART(state, data) {
      state.cart.push({...data.item, quantity : data.quantity})
      state.cart = [...new Set(state.cart)]

      state.order.products.push({
        product_id: data.item.id,
        quantity: data.item.quantity || data.quantity || 1,
        merchant_id: data.merchant_id,
        price: data.item.price,
        little: state.order.little,
        retail_price: data.item.retail_price,
      })
      state.cart.products = [...new Set(state.order.products)]
    },

    // Set Order Details
    SET_ORDER_DETAILS(state, data) {
      
      state.order.eletricity_bill = data.eletricity_bill
      state.order.product_type = data.product_type ? data.product_type : data.type
      data.pivot
        ? (state.order.merchant_id = data.pivot.merchant_id)
        : (state.order.merchant_id = data.merchant_id)
      state.order.total = state.order.products.reduce(function (accumulator, item) {
        return item.retail_price ?  accumulator + item.retail_price * item.quantity :accumulator + item.price * item.quantity;
      }, 0);
      state.order.reservation_fee = state.cart.reduce(function (accumulator, item) {
        return accumulator + item.approval_fee;
      }, 0);
      state.order.little = data.litres
      state.order.meta = data.meta
      state.order.discount = data.discount
      state.order.quantity = state.order.products.reduce(function (accumulator, item) {
        return accumulator + item.quantity;
      }, 0);
    },

    // Increase Cart Quantity
    INCREASE_QTY: (state, data) => {
      let product = state.cart.find((item) => item.id === data)
      let order = state.order.products.find((item) => item.product_id === data)
      product.quantity++
      order.quantity++
    },

    // Decrease Cart Quantity
    DECREASE_QTY: (state, data) => {
      let product = state.cart.find((item) => item.id === data)
      let order = state.order.products.find((item) => item.product_id === data)
      product.quantity--
      order.quantity--
      if (product.quantity < 1) {
        product.quantity = 1
      }
      if (order.quantity < 1) {
        order.quantity = 1
      }
    },

    // Remove item from Cart
    REMOVE_ITEM: (state, data) => {
      let index = state.cart.findIndex(function (item) {
        return item.id === data
      })
      let orderIndex = state.order.products.findIndex(function (item) {
        return item.product_id === data
      })
   
   
      if (index !== -1) state.cart.splice(index, 1)
      if (orderIndex !== -1) state.order.products.splice(index, 1)
      state.order.reservation_fee = state.cart.reduce(function (accumulator, item) {
        return accumulator + item.approval_fee;
      }, 0);
      state.order.total = state.order.products.reduce(function (accumulator, item) {
        return item.retail_price ?  accumulator + item.retail_price * item.quantity : accumulator + item.price * item.quantity;
      }, 0);
      state.order.quantity = state.order.products.reduce(function (accumulator, item) {
        return accumulator + item.quantity;
      }, 0);
    },

    EMPTYCART: (state) => {
      state.cart = []
      state.order.products = []
      state.order.product_type = null
      state.order.address = ''
      state.order.eletricity_bill = null
      state.order.reservation_fee = 0
    },

    // 

    SET_SWAP_CURRENT_STATE: (state, id)  => {
      state.swapFormCurrentState = id
    },
    REDUCE_SWAP_CURRENT_STATE: (state)  => {
      state.swapFormCurrentState = state.swapFormCurrentState - 1
    },
    RESET_SWAP_CURRENT_STATE: (state)  => {
      state.swapFormCurrentState = 1
      state.swapDetails = {}
    },
    ADD_SWAP_CURRENT_STATE: (state)  => {
      state.swapFormCurrentState = state.swapFormCurrentState + 1
    },
    SET_SWAP_DETAILS : (state, data) => {
      state.swapDetails[data.key] = data.value
    },
    EXCHANGE_RATES : (state, data) => {
      state.exchangeRates = data
    }
  },

  // Actions
  actions: {
    // Get Products and Categories
    async getOptions({ commit }) {
      let res = await request().get(`/options`)
      commit('OPTIONS', res.data.data.product_categories)
      commit('OPTIONS_DATA', res.data.data)
      commit('PRODUCTS', res.data.data.products)
      return res
    },
    // Create Order
    async makeOrder({ commit }, payload) {
      let res = await request().post(`/orders`, payload)
      // commit('ORDER', res.data)

      return res.data
    },

    // Buy Energy
    async buyEnergy({ commit }, payload) {
      let res = await request().post(`/orders`, payload)
      commit('ORDER', res.data)

      return res.data
    },
    async getDevices({ commit }) {
      let res = await request().get(`/devices`)
      commit('DEVICES', res.data)

      return res.data
    },
    async getDevice({ commit }, id) {
      let res = await request().get(`/devices/${id}`)
      commit('DEVICE', res.data)

      return res.data
    },
    async getRates({ commit }) {
      let res = await request().get(`/exchanges/refresh`)
      commit('EXCHANGE_RATES', res.data.data)

      return res.data
    },

    // Add to Cart
    // async addToCart({ commit }, item) {
    //   const res = await request().post('/cart', item, {
    //     headers: {
    //       Authorization: 'Bearer' + item.access_token,
    //     },
    //   })
    //   commit('ADD_TO_CART', res.data)
    //   return res
    // },
  },

  // Getters
  getters: {
    // Get a product based on it's name from product_categories
    getProduct: (state) => (id) => {
      let product = state.products.filter((product) => {
        if (product.categories.length > 0) {
          return product.categories[0].name == id
        }
      })
      return product
    },

    getRate: (state)  => (code) => {
      if (state.exchangeRates) {
        return state.exchangeRates.find((rate) => rate.code  == code && rate.name === code )
        
      }
    },
    findProduct: (state)  => (id) => {
      return state.products.find((product) => product.name == id )
    },

    // Get Accessories
    getAccessories: (state) => (id) => {
      let accessories = state.products.filter((product) => {
        return product.accessory
      })

      return accessories
    },
    // Get Accessory
    getAccessory: (state) => (id) => {
      let accessories = state.products.filter((product) => {
        return product.accessory
      })
      

      let accessory = accessories.find((product) => product.name == id)
      return accessory
    },
  },
}
