const Merchants = () => import('../../merchants/views/Merchants')
const CreateMerchant = () => import('../../merchants/views/CreateMerchant')
const CreateMerchantProduct = () =>
  import('../../merchants/views/CreateProduct')
const MerchantProducts = () => import('../../merchants/views/MerchantProducts')
const MerchantAddProducts = () => import('../../merchants/views/AddProducts')
const RequestProducts = () => import('../../merchants/views/RequestProducts')
const MerchantProductsDetails = () =>
  import('../../merchants/views/ProductDetails')
const MerchantCustomers = () => import('../../merchants/views/Customers')
const MerchantSales = () => import('../../merchants/views/Orders')
const MerchantSalesDetails = () => import('../../merchants/views/OrderDetails')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/merchants',
    name: 'Merchants',
    component: DefaultLayout,
    redirect: '/merchants',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = store.getters.get_access_token
        if (hasPermission) {
          if (to.name != 'merchants.create' && !store.getters.isDistributor) {
            next({
              name: 'Login', // back to safety route //
              query: { redirectFrom: to.fullPath },
            })
          } else {
            next()
          }
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/merchants',
        name: 'Dashboard - Merchants',
        component: Merchants,
      },
      {
        path: '/merchants/create',
        name: 'merchants.create',
        component: CreateMerchant,
      },
      {
        path: '/merchants/product/create',
        name: 'merchants.product.create',
        component: CreateMerchantProduct,
      },
      {
        path: '/merchants/products',
        name: 'merchants.products',
        component: MerchantProducts,
        meta: {
          showDashboardSwitch: true,
        },
      },
      {
        path: '/merchants/order',
        name: 'merchants.order',
        component: RequestProducts,
      },
      {
        path: '/merchants/products/:id/add',
        name: 'merchants.products.add',
        component: MerchantAddProducts,
      },
      {
        path: '/merchants/products/:id',
        name: 'merchants.products.details',
        component: MerchantProductsDetails,
      },
      {
        path: '/merchants/customers',
        name: 'merchants.customers',
        component: MerchantCustomers,
      },
      {
        path: '/merchants/sales',
        name: 'merchants.sales',
        component: MerchantSales,
      },
      {
        path: '/merchants/sales/:id',
        name: 'merchants.order.details',
        component: MerchantSalesDetails,
      },
      //   {
      //     path: '/orders/:id',
      //     component: Order,
      //   },
    ],
  },
]
