const Orders = () => import('../../orders/views/Orders')
const Order = () => import('../../orders/views/Order')
import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/orders',
    name: 'Orders',
    component: DefaultLayout,
    redirect: '/orders',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/orders',
        name: 'Dashboard - Orders',
        component: Orders,
      },
      {
        path: '/orders/:id',
        name: 'Dashboard - Order Details',
        component: Order,
      },
    ],
  },
]
