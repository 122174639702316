<template>
  <!-- <main>
    <section>
      <div class="toggle-btn" id="_1st-toggle-btn">
        <input type="checkbox" />
        <span></span>
      </div>
    </section>
  </main> -->

  <svg
    id="theme-toggle-dark-icon"
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-width="2"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
    />
  </svg>
  <svg
    id="theme-toggle-light-icon"
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-width="2"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
    />
  </svg>
  <button
    id="theme-toggle"
    type="button"
    class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
  >
    <svg
      id="theme-toggle-dark-icon"
      class="w-5 h-5 hidden"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
      ></path>
    </svg>
    <svg
      id="theme-toggle-light-icon"
      class="w-5 h-5 hidden"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        fill-rule="evenodd"
        clip-rule="evenodd"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {}
</script>

<style scoped>
.toggle-btn {
  position: relative;
  width: 45px;
  height: 25px;
  margin: 0 auto;
  border-radius: 30px;
}

input[type='checkbox'] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

/* First toggle btn */

#_1st-toggle-btn span {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  opacity: 1;
  background-color: #fff;
  box-shadow: 0px 2px 5px #d9d9d9;
  border-radius: 40px;
  transition: 0.2s ease background-color, 0.2s ease opacity;
}

#_1st-toggle-btn span:before,
#_1st-toggle-btn span:after {
  content: '';
  position: absolute;
  top: 6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: 0.5s ease transform, 0.2s ease background-color;
}

#_1st-toggle-btn span:before {
  background-color: #fff;
  transform: translate(-58px, 0px);
  z-index: 1;
}

#_1st-toggle-btn span:after {
  background-color: #000b18;
  transform: translate(8px, 0px);
  z-index: 0;
}

#_1st-toggle-btn input[type='checkbox']:checked + span {
  background-color: #000b18;
}

#_1st-toggle-btn input[type='checkbox']:active + span {
  opacity: 0.5;
}

#_1st-toggle-btn input[type='checkbox']:checked + span:before {
  background-color: #000b18;
  transform: translate(26px, -19px);
}

#_1st-toggle-btn input[type='checkbox']:checked + span:after {
  background-color: #fff;
  transform: translate(26px, -2px);
}

/* First toggle btn completed */
</style>
