// Import request/axios config
import request from '@/services/lifestyle/axios'

import 'mosha-vue-toastify/dist/style.css'

export default {
  namespaced: true,
  state: {
    merchant: [],
    merchantProducts: null,
    item: 'hhh',
  },

  // Mutations
  mutations: {
    SET_MERCHANT(state, data) {
      state.merchant = data
    },
    SET_MERCHANT_PRODUCTS(state, data) {
      state.merchantProducts = data
    },
    SET_MERCHANT_PRODUCTSS(state, data) {
      state.merchantProductss = data
    },
  },

  // Actions
  actions: {
    // Get MERCHANT
    async getMerchant({ commit }) {
      let res = await request().get(`/merchants`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT', res.data.data)
      return res
    },
    // Get MERCHANT PRODUCTS
    async getMerchantProductss({ commit }) {
      let res = await request().get(`/merchant/products`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT_PRODUCTSS', res.data.data)
      return res
    },
    // Get MERCHANT PRODUCT
    async getMerchantProduct({ commit }, id) {
      let res = await request().get(`/merchant/product/${id}`, {
        'Content-Type': 'application/json',
      })
      // commit('SET_MERCHANT_PRODUCTSS', res.data.data)
      return res
    },

    // Get MERCHANT PRODUCTS
    async getMerchantProducts({ commit }, id) {
      try {
        let res = await request().get(`/merchants/${id}/products`, {
          'Content-Type': 'application/json',
        })
        commit('SET_MERCHANT_PRODUCTS', res.data.data)
      
      
        return res
      } catch (error) {
        commit('SET_MERCHANT_PRODUCTS', null)
        return null
      }
    },

    // Get MERCHANT BY LOCATION
    async getMerchantByLocation({ commit }, data) {
      try {
        let res = await request().get(
          `/merchants?lat=${data.lat}&lng=${data.lng}&paginate=20`,
          {
            'Content-Type': 'application/json',
          },
        )
        // commit('SET_MERCHANT_PRODUCTS', res.data.data)

        return res.data
      } catch (error) {
        return null
      }
    },
    // Get USER BY UID
    async getUserById({ commit }, id) {
      try {
        let res = await request().get(`/user/${id}`, {
          'Content-Type': 'application/json',
        })

        return res.data
      } catch (error) {
        return null
      }
    },

    // CREATE MERCHANT
    async createMerchant({ commit }, data) {
      let res = await request().post(`/merchants`, data, {
        'Content-Type': 'application/json',
      })
      // commit('APPROVAL_FEE', res.data)
      //
      return res
    },

    // ADD MERCHANT PRODUCT
    async addProduct({ commit, dispatch }, data) {
      let res = await request().post(
        `/merchants/1/products`,
        { products: data.products },
        {
          'Content-Type': 'application/json',
        },
      )
      dispatch('getMerchant')
      dispatch('getMerchantProducts')
      return res
    },
    // UPDATE MERCHANT
    async patchMerchant({ commit, dispatch }, data) {
      let res = await request().patch(`/merchants/1`, data, {
        'Content-Type': 'application/json',
      })
      dispatch('getMerchant')
      // commit('APPROVAL_FEE', res.data)
      //
      return res
    },
  },

  // Getters
  getters: {},
}
