<template>
  <section class="sticky mb-0 py-3 flex items-center justify-between">
    <router-link to="/" class="navbar-brand pl-0 pl-md-4"
      ><img src="@/assets/images/orange__logo.png" alt=""
    /></router-link>

    <div class="flex gap-5 justify-center text-center hidden lg:flex">
      <router-link
        to="/shop/CHIOMA%20-%20Clean%20Power/HomeSolar%203"
        class="text-black font-bold"
      >
        CHIOMA
      </router-link>
      <router-link
        to="/shop/KIKE%20-%20Clean%20Cooking/KIKE%20GAS"
        class="text-black font-bold"
      >
        KIKE
      </router-link>
      <router-link to="/obidder" class="text-black font-bold">
        Digital Estate
      </router-link>
      <router-link to="/shop" class="text-black font-bold">
        Products
      </router-link>
      <!-- <router-link to="/shop" class="text-black font-bold">
        Accessories
      </router-link> -->
      <router-link to="/partners" class="text-black font-bold">
        <button
          class="btn btn-save btn-sm"
          type="button"
        >
          Become a Partner
          
        </button>
      </router-link>
    </div>
    <div class="flex gap-3 items-center">
      <CurrencySwitch />
      <router-link to="/cart" class="ca t ml-2 position-relative" style="color: #fd7e14">
        <svg width="22" height="22" viewBox="0 0 24 24" style="fill: #fd7e14">
          <path
            fill="currentColor"
            d="M12 9q-.425 0-.712-.288Q11 8.425 11 8V6H9q-.425 0-.712-.287Q8 5.425 8 5t.288-.713Q8.575 4 9 4h2V2q0-.425.288-.713Q11.575 1 12 1t.713.287Q13 1.575 13 2v2h2q.425 0 .713.287Q16 4.575 16 5t-.287.713Q15.425 6 15 6h-2v2q0 .425-.287.712Q12.425 9 12 9ZM7 22q-.825 0-1.412-.587Q5 20.825 5 20q0-.825.588-1.413Q6.175 18 7 18t1.412.587Q9 19.175 9 20q0 .825-.588 1.413Q7.825 22 7 22Zm10 0q-.825 0-1.412-.587Q15 20.825 15 20q0-.825.588-1.413Q16.175 18 17 18t1.413.587Q19 19.175 19 20q0 .825-.587 1.413Q17.825 22 17 22ZM7 17q-1.15 0-1.737-.988q-.588-.987-.013-1.962L6.6 11.6L3 4H2q-.425 0-.712-.288Q1 3.425 1 3t.288-.713Q1.575 2 2 2h1.65q.275 0 .525.15t.375.425L8.525 11h7.025l3.6-6.5q.125-.225.35-.363Q19.725 4 20 4q.575 0 .863.487q.287.488.012.988L17.3 11.95q-.275.5-.738.775Q16.1 13 15.55 13H8.1L7 15h11q.425 0 .712.287q.288.288.288.713t-.288.712Q18.425 17 18 17Z"
          />
          
        </svg>
        <span class="badge" v-if="cart && cart.length"> {{ cart.length }} </span>
      </router-link>
      <AppHeaderDropdown  />

      <span class="px-2 cursor-pointer text-black" @click="onToggleSidebar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </span>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import AppHeaderDropdown from './DashboardHeaderDropdown'

import DarkModeToggler from './DarkModeToggler.vue'
import CurrencySwitch from './CurrencySwitch.vue'
import { logo } from '@/assets/brand/logo'
export default {
  name: 'AppHeader',
  components: {
    DarkModeToggler,
    
    AppHeaderDropdown,
    CurrencySwitch,
  },
  setup() {
    return {
      logo,
    }
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations(['toggleSidebar']),
    onToggleSidebar(){
        this.toggleSidebar(!this.sidebarVisible)
    },
    toggleAppMode() {
      let app = document.body
      if (app.classList.contains('dark')) {
        app.classList.remove('dark')
      } else {
        app.classList.add('dark')
      }
    },
    toggleColorScheme() {
      // TODO: Fire axios call to save preference so it can be loaded with PHP
      // That should prevent the "page flicker" during loading
      // This works great, but ignores user preference - We need light, dark, and system option on backend profile
      // (default should be system until user clicks color switcher, for now we are defaulting to light)
      if (document.body.classList.contains('dark')) {
        document.body.classList.remove('dark')
        localStorage.setItem('ColorScheme', 'light')
      } else {
        document.body.classList.add('dark')
        localStorage.setItem('ColorScheme', 'dark')
      }
    },
  },

  computed: {
    ...mapState(['options', 'sidebarVisible']),
    ...mapState('shop', [
      'cart',
      
    ]),
  },
}
</script>

<style scoped>
.sticky {
  position: sticky !important;
  top: 0;
  z-index: 1029;
  background-color: #fff;
}
.header {
  border-bottom: none !important;
}
.header-nav .nav-link {
  font-weight: 700;
  font-size: medium;
  color: #303030;
  cursor: pointer;
}
.a .nav-link {
  margin: 0 1rem !important;
}
.icon {
  cursor: pointer;
}
.dark .dashHeader {
  background: #000b18 !important;
}
.dark .a .nav-link {
  color: #c5c5c5;
}
.dark .icon {
  color: #bcbecb !important;
}

.badge{
  position: absolute;
  width: 10px;
    height: 10px;
    color: #fff;
    background: #000;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: -5px;
    right: -10px;
}
</style>
