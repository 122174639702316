// Import request/axios config
import request from '@/services/lifestyle/axios'

import 'mosha-vue-toastify/dist/style.css'

export default {
  namespaced: true,
  state: {
    merchant: [],
    merchantProducts: null,
    merchantProductss: null,
    customers: null,
    merchant_orders: null,
    item: 'hhh',
    isMerchantDashboard : false
  },

  // Mutations
  mutations: {
    TOGGLE_MERCHANT_DASHBOARD(state) {
      state.isMerchantDashboard = !state.isMerchantDashboard

    },
    SET_MERCHANT(state, data) {
      state.merchant = data

    },
    SET_MERCHANT_PRODUCTS(state, data) {
      
      state.merchantProducts = data
      
    },
    SET_MERCHANT_PRODUCTSS(state, data) {
      
      state.merchantProductss = data
      
    },
    SET_MERCHANT_CUSTOMERS(state, data) {
      
      state.customers = data.data
    },

    // MERCHANT ORDERS
    SET_MERCHANT_ORDERS(state, data) {
      
      state.merchant_orders = data.data
    },
  },

  // Actions
  actions: {

    // 
    async toggleMerchantDashboard({ commit }) {
      commit('TOGGLE_MERCHANT_DASHBOARD')
    },



    // Get MERCHANT
    async getMerchant({ commit }) {
      let res = await request().get(`/merchant`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT', res.data.data)
      
      return res
    },

    // Get MERCHANT PRODUCTS
    async getMerchantProductss({ commit }) {
      let res = await request().get(`/merchant/products`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT_PRODUCTSS', res.data.data)
      
      return res
    },
    // Get MERCHANT PRODUCTS
    async getMerchantProducts({ commit }) {
      let res = await request().get(`/merchants`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT_PRODUCTS', res.data.data)
      
      return res
    },

    // CREATE MERCHANT
    async createMerchant({ commit }, data) {
      try {
        let res = await request().post(`/merchant/store`, data, {
          'Content-Type': 'application/json',
        })
        
        return res
      } catch (error) {
        
        return error.response
      }
    },

    // Get USER BY UID
    async getUserById({ commit }, id) {
      try {
        let res = await request().get(`/user/${id}`, {
          'Content-Type': 'application/json',
        })
        

        return res.data
      } catch (error) {
        return null
      }
    },

    // ADD MERCHANT PRODUCT
    async addProduct({ commit, dispatch }, data) {
      
      let res = await request().post(
        `/merchants/1/products`,
        { products: data.products },
        {
          'Content-Type': 'application/json',
        },
      )
      
      dispatch('getMerchant')
      dispatch('getMerchantProducts')
      return res
    },
    // UPDATE MERCHANT
    async createMerchantProduct({ commit, dispatch }, data) {
      
      let res = await request().post(
        `/merchant/products`,
        { products: [data] },
        {
          'Content-Type': 'application/json',
        },
      )
      
      dispatch('getMerchant')
      
      //
      return res
    },

    // Get MERCHANT CUSTOMERS
    async getCustomers({ commit }) {
      let res = await request().get(`/merchant/customers`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT_CUSTOMERS', res.data)
      
      return res
    },

    // MERCHANT ORDERS
    async getMerchantOrders({ commit }) {
      let res = await request().get(`/merchant/orders`, {
        'Content-Type': 'application/json',
      })
      commit('SET_MERCHANT_ORDERS', res.data)
      
      return res
    },
    async getMerchantOrder({ commit }, id) {
      let res = await request().get(`/merchant/orders/${id}`, {
        'Content-Type': 'application/json',
      })
      
      return res
    },
  },

  // Getters
  getters: {},
}
