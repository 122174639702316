// Import request/axios config
import request from '@/services/lifestyle/axios'

import 'mosha-vue-toastify/dist/style.css'

export default {
  namespaced: true,
  state: {
    orders: null,
    order: null,
  },

  // Mutations
  mutations: {
    SET_ORDERS(state, data) {
      state.orders = data
      // console.log(state.orders)
    },
    SET_ORDER(state, data) {
      state.order = data
    },
  },

  // Actions
  actions: {
    // Get Reservations request

    async getOrders({ commit }, params = { page: 1 }) {
      let res = await request().get(`/orders`, { params })
      commit('SET_ORDERS', res.data)

      // window.scrollTo(0, 0)
      return res.data
    },

    // Get Reservation request

    async getOrder({ commit }, id) {
      // console.log(id)
      let res = await request().get(`/orders/${id}`, {
        'Content-Type': 'application/json',
      })
      commit('SET_ORDER', res.data)

      return res.data.data
    },

    // PAY APPROVAL FEE
    async payApprovalFee({ commit }, data) {
      let res = await request().post(`/payments/approvalfee`, data, {
        'Content-Type': 'application/json',
      })
      // commit('APPROVAL_FEE', res.data)
      //
      // return res
    },
    // PAY ORDER/ACCESSORY FEE
    async payAccessoryFee({ commit }, data) {
      let res = await request().post(`/payments/order`, data, {
        'Content-Type': 'application/json',
      })
      // commit('APPROVAL_FEE', res.data)
      //
      return res
    },
  },

  // Getters
  getters: {
    getOrder: (state) => (id) => {
      // console.log(id)
      // console.log(state.orders)
      let product = state.orders.find((product) => product.id == id)
      // console.log(product)
      return product
    },
    getReservation: (state) => (id) => {
      // console.log(id)
      // console.log(state.orders)
      let product = state.orders.data.filter(
        (product) => product.reservation !== null,
      )
      // console.log(product)
      return product
    },
  },
}
