import { createRouter, createWebHistory } from 'vue-router'
// import store from '../store'

import shopRoutes from '../modules/shop/router'
import authRoutes from '../modules/auth/router'
import dashboardRoutes from '../modules/user/dashboard/router'
import orderRoutes from '../modules/user/dashboard/views/orders/router'
import reservationsRoutes from '../modules/user/dashboard/views/reservations/router'
import siteReportRoutes from '../modules/user/dashboard/views/sitereports/router'
import obidderRoutes from '../modules/user/obidder/router'
import transactionRoutes from '../modules/user/dashboard/views/transaction/router'
import paymentRoutes from '../modules/user/dashboard/views/payment/router'
import invoiceRoutes from '../modules/user/dashboard/views/invoices/router'
import referralRoutes from '../modules/user/dashboard/views/referrals/router'
import profillingRoutes from '../modules/user/dashboard/views/profiling/router'
import merchantsRoutes from '../modules/merchants/router'
import marketplaceRoutes from '../modules/user/dashboard/views/marketplace/router'
import blogsRoutes from '../modules/blogs/router'

const KikePage = () => import('@/modules/products/views/Kike.vue')
const ChiomaPage = () => import('@/modules/products/views/Chioma.vue')
// const Error401 = () => import('../views/Error401')
// const Error404 = () => import('../views/Error404')

const GuestInvoicePage = () => import('../views/invoice/Show.vue')

const baseRoutes = [
  // {
  //   path: '/',
  //   redirect: '/dashboard',
  // },
  // {
  //   path: '/401',
  //   name: 'errors.401',
  //   component: Error401,
  //   meta: { layout: 'auth', title: 'Permission Error' },
  // },

  {
    path: '/invoice/:reference',
    name: 'invoice.show',
    component: GuestInvoicePage,
  },
  {
    path: '/kike',
    name: 'KikePage',
    component: KikePage,
  },
  {
    path: '/chioma',
    name: 'ChiomaPage',
    component: ChiomaPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: baseRoutes.concat(
    shopRoutes,
    blogsRoutes,
    authRoutes,
    dashboardRoutes,
    orderRoutes,
    reservationsRoutes,
    siteReportRoutes,
    obidderRoutes,
    transactionRoutes,
    paymentRoutes,
    referralRoutes,
    profillingRoutes,
    invoiceRoutes,
    merchantsRoutes,
    marketplaceRoutes,
  ),
})

export default router
