// Import request/axios config
import request from '@/services/axios'
import router from '@/router/index'

export default {
  namespaced: true,
  state: {
    auth: null,
    user_estate_flats: [],
    manager_estates: [],
    access_token: null,
    options: [],
    options_data: [],
    user_currency: '',
  },

  // Mutations
  mutations: {
    OPTIONS(state, data) {
      state.options = data
    },
    OPTIONS_DATA(state, data) {
      state.options_data = data
    },
    LOGIN(state, data) {
      state.auth = data

      state.user_estate_flats = data.user.user_estate_flats
      state.manager_estates = data.user.manager_estates
      // state.user = data.auth.user
      state.access_token = data.access_token
    },
    REGISTER(state, data) {
      state.registered = Boolean(data)
    },

    SETUSER_CURRENCY(state) {
      state.user_currency = state.options_data.user_options?.currency ?? 'USD'
    },

    SET_CURRENCY_FROM_AUTH(state, data) {
      state.currency = data
    },
    async LOGOUT(state) {
      state.auth = null
      state.access_token = null
      // delete axios.defaults.headers.common['Authorization']

      
       localStorage.removeItem('vuex')
       

      //  window.location.href = '/login'


    },
  },

  // Actions
  actions: {
    // Login request
    async login({ commit, dispatch }, data) {
      let res = await request().post(`/auth/login`, data, {
        'Content-Type': 'application/json',
      })

      commit('SETUSER_CURRENCY')
      commit('LOGIN', res.data)

      return res
    },

    // Register request
    async register({ commit }, data) {
      let res = await request().post(`/auth/register`, data, {
        'Content-Type': 'application/json',
      })
      commit('REGISTER', res.data)

      return res
    },

    // Change Password
    async resetPassword({ commit }, data) {
      let res = await request().post(`/auth/reset-password`, data, {
        'Content-Type': 'application/json',
      })

      return res
    },

    // Update Password
    async forgotPassword({ commit }, data) {
      let res = await request().post(`/auth/forgot-password`, data, {
        'Content-Type': 'application/json',
      })

      return res
    },

    // Get options request, Products and Products Categories
    async getOptions({ commit }) {
      let res = await request().get(`/options`)
      commit('OPTIONS', res.data.data)
      commit('OPTIONS_DATA', res.data.data)
      commit('SETUSER_CURRENCY')
      return res
    },

    // Get Currency
    async getCurrency({ commit }) {
      let res = await request().get(`/account/options/currency`, {
        'Content-Type': 'application/json',
      })
      commit('SETUSER_CURRENCY')
      return res
    },

    // Change Currency
    async changeCurrency({ commit, dispatch }, data) {
      let res2 = await request().patch(
        `/account/options`,
        { currency: data.currency },
        {
          'Content-Type': 'application/json',
        },
      )

      dispatch('getOptions')
      commit('SETUSER_CURRENCY')
      return res2
    },
  },

  // Getters
  getters: {
    get_access_token: (state) => state.auth.access_token,
  },
}
