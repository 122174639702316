import axios from 'axios'
import config from '@/config'
import router from '@/router/index'
// import { notify } from "~/plugins/notiwind/index.esm"

export default function () {
  // console.log(config.lifestyleUrl)
  let token

  //read and convert to object
  let storeObj = JSON.parse(localStorage.getItem('vuex'))
  if (storeObj && storeObj.auth) {
    token = storeObj.auth.access_token
  }

  // When sending a request, if there is a token, it needs to be attached to the request header
  let instance = axios

  if (token) {
    instance = axios.create({
      baseURL: `${config.lifestyleUrl}`,
      headers: {
        authorization: 'Bearer ' + token,
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        // 'Access-Control-Max-Age': 600,
      },
    })
  } else {
    instance = axios.create({
      baseURL: `${config.lifestyleUrl}`,
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        // 'Access-Control-Max-Age': 600,
      },
    })
  }

  instance.interceptors.response.use(
    (resp) => {
      return resp
    },
    (err) => {
      // Permission denied.
      if (err.response && err.response.status === 403) {
        //reject and retrieve error message
        // notify({
        //   group: "error",
        //   title: 'Error occured',
        //   text: err.response.data.detail
        // }, 6000)
        return Promise.reject(err)
      }
      // if (err.response && err.response.status === 404) {
      //   // console.log(err)
      //   window.location.href = '/404'
      // }
      if (err.response && err.response.status === 500) {
        console.log(err)
        // window.location.href = '/500'
        //reject and retrieve error message
        // notify(
        //   {
        //     group: 'error',
        //     title: 'Error occured',
        //     text: err.response.data.detail,
        //   },
        //   6000,
        // )
        return Promise.reject(err)
      }

      //  Invalid token, Not Authenticated.
      if (err.response && err.response.status === 401) {
        // console.log(localStorage.getItem('vuex'));
        // localStorage.clear()
        // if (localStorage.getItem('vuex')) {
        //   localStorage.removeItem('vuex')
        //   localStorage.setItem('vuex', JSON.stringify(storeObj))
        // }
        //   else {
        //     localStorage.removeItem('vuex')
        //     localStorage.setItem('vuex', JSON.stringify(storeObj))
        //   }
        // console.log(router)
        //  window.location.href = '/login'

        // console.log(err.response);
        // let params = window.location.pathname
        // router.push(`/`)
      }

      // notify({
      //     group: "error",
      //     title: 'Error occured',
      //     text: err.message
      // }, 8000)
      return Promise.reject(err)
    },
  )

  return instance
}
