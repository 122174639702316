const Marketplace = () => import('../views/MarketPlace')
const ProductDetails = () => import('../views/ProductDetails')
const MarchantProducts = () => import('../views/MerchantProducts')
const MarketplaceProducts = () => import('../views/ProductDetails')
const MerchantProductDetails = () => import('../views/MerchantProductDetails')
const MerchantProfile = () => import('../views/MerchantProfile')

import DefaultLayout from '@/layouts/DefaultLayout'
import ShopLayout from '@/layouts/ShopLayout'
import store from '@/store/index'

export default [
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: ShopLayout,
    redirect: '/marketplace',
    async beforeEnter(to, from, next) {
      try {
        var hasPermission = store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next()
        }
      } catch (e) {}
    },
    children: [
      {
        path: '/marketplace',
        name: 'Marketplace',
        component: Marketplace,
      },
      {
        path: '/marketplace/product/:id',
        name: 'Marketplace Product',
        component: ProductDetails,
      },
      {
        path: '/marketplace/products',
        name: 'merchant.products',
        component: MarchantProducts,
      },
      {
        path: '/marketplace/merchant/:id/:name',
        name: 'Marketplace Products',
        component: MarketplaceProducts,
      },
      {
        path: '/@:uid',
        name: 'Marketplace Profile',
        component: MerchantProfile,
      },
      {
        path: '/@:uid/products/:id',
        name: 'Marchant Product ',
        component: MerchantProductDetails,
      },
    ],
  },
]
