<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="
          font-bold
          inline-flex
          justify-center
          items-center
          w-full
          rounded-md
          px-3
          px-md-4
          py-2
          p-0
          bg-transparent
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-offset-gray-100
          focus:ring-indigo-500
        "
      >
        <img src="@/assets/images/avatar.png" alt="" style="width: 100%" class="avatar" />

        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-4" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="
          origin-top-right
          absolute
          right-0
          mt-2
          w-40
          rounded-md
          shadow-lg
          bg-white
          ring-1 ring-black ring-opacity-5
          focus:outline-none
        "
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              :class="[
                active ? 'text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-xs hidden',
              ]"
              >Account</a
            >
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <div
            type="button"
              data-bs-toggle="modal"
              data-bs-target="#myModal2"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >Welcome Note</div
            >
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <router-link
              to="/system_design"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >IFAA Syatem Design</router-link
            >
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <router-link
              to="/profile"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >Profile</router-link
            >
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <router-link
              to="/payments"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >Payments</router-link
            >
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <router-link
              to="/partners"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >Partners</router-link
            >
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="isMerchantDashboard">
            <button
            @click="visitUserDash"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >User Dashboard</button
            >
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="!isMerchantDashboard && isMerchant">
            <button
            @click="visitMercDash"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >My Business</button
            >
          </MenuItem>
  
          <MenuItem v-slot="{ active }">
            <a
              @click="logUserOut"
              href="#"
              :class="['active text-black block px-4 py-2 text-xs font-bold']"
              >Logout  </a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
  <WelcomeModal />
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import WelcomeModal from '@/components/WelcomeModal.vue'

export default {
  data() {
    return {
      payload: {
        currency: '',
        locale: '',
      },
    }
  },

  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    WelcomeModal
  },

  computed: {
    ...mapState('auth', ['auth']),
    ...mapState('merchants', ['merchant', 'isMerchantDashboard']),
    isMerchant(){
      return Boolean(this.merchant && Object.keys(this.merchant).length)
    }
  },

  methods: {
    ...mapActions('auth', ['changeCurrency']),
    ...mapActions('auth', ['getCurrency']),
    ...mapMutations('auth', ['LOGOUT']),
    ...mapActions('merchants', ['getMerchant', 'toggleMerchantDashboard']),
    async logUserOut() {
      this.LOGOUT()
      
      await this.$moshaToast(`You have been logged out`, {
        hideProgressBar: true,
        type: 'success',
        position: 'top-right',
      })
      await this.$router.push('/login')
    },
    async setCurrency(a, b) {
      let payload = { currency: a, locale: b }
    },

    visitUserDash(){
      this.toggleMerchantDashboard()
      this.$router.push('/dashboard/overview')
    },
    visitMercDash(){
      this.toggleMerchantDashboard()
      this.$router.push('/merchant/products')
    }
  },

  async created() {
    this.getMerchant()
  },
}
</script>


<style scoped>
@media(min-width: 768px){
  .avatar{
    width: 50% !important;
  }
}
</style>
