import request from '@/services/axios'
import lifestylerequest from '@/services/lifestyle/axios'
let today = new Date().toLocaleDateString()

// const baseUrl = 'https://app.owatts.io/api/v1/'
// changeBaseUrl
import config from '@/config/'
export default {
  namespaced: true,
  state: {
    options: [],
    products: [],
    accessory: [],
    franchisee: [],
    installer: [],
    options_data: null,
    socket_auth: {},
    user_products: [],
    autoGen: null,
    gen_log: null,
  },
  mutations: {
    // OPTIONS(state, data) {
    //   state.options = data
    // },
    // OPTIONS_DATA(state, data) {
    //   state.options_data = data
    // },
    // FRANCHISEE(state, data) {
    //   state.franchisee = data
    // },
    // INSTALLER(state, data) {
    //   state.installer = data
    // },
    SET_AUTOGEN_DATA(state, data) {
      state.autoGen = data
    },
    SET_SOCKET_AUTH(state, data) {
      state.socket_auth = data
    },
    SET_GEN_LOG(state, data) {
      state.gen_log = data
    },
    SET_USER_PRODUCTS(state, data) {
      data.forEach((product) => {
        if (product.type == 1) {
          product.name = 'Prepaid Meter'
        }
        if (product.type == 2) {
          product.name = 'Kike Gas'
        }
        if (product.type == 3) {
          product.name = 'SmartHome'
        }
        if (product.type == 4) {
          product.name = 'Genstarter'
        }
        if (product.type == 5) {
          product.name = 'AutoBidder'
        }
        if (product.type == 6) {
          product.name = 'Gateway'
        }
        if (product.type == 7) {
          product.name = 'Inverter'
        }
        return product
      })
      state.user_products = data
    },
  },
  actions: {
    async fetchDeviceConsumptionData({ commit, rootState }, query) {
      try {
        const url = new URL(config.iotBaseUrl + 'consumption/detail')
        url.search = new URLSearchParams(query)
        const res = await request().get(url, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        return res.data.data
      } catch (error) { }
    },

    async fetchUserProducts({ commit, rootState, state }, query) {
      try {
        const res = await request().get(config.baseUrl + 'devices')
        // console.log('devices', res)
        // console.log(config)
        commit('SET_USER_PRODUCTS', res.data.data)
      } catch (error) {
        // console.log('getUserProducts ERROR', error)
      }
    },

    // fetch device realtime data from iot server.
    async fetchDeviceData({ commit, rootState, state }, imei) {
      try {
        let url = new URL(config.iotBaseUrl + `device/detail?imei=${imei}`)
        const res = await request().get(url, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        return res.data.data
      } catch (error) {
        return error.response
      }
    },
    // fetch device realtime data from iot server.
    async toggleAcCharge({ commit, rootState, state }, data) {
      try {
        let url = new URL(config.iotBaseUrl + `ac_charge_enable`)
        const res = await request().put(url, data, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        alert(res.data.message)
        return res.data.data
      } catch (error) {
        console.log(error.response)
        return error.response
      }
    },
    // Toggle Kike State Switch
    async switchKikeState({ commit, rootState, state }, data) {
      if (confirm(data.status ? 'Switch On Device?' : 'Switch Off Device?')) {
        // console.log(data)
        try {
          let url = new URL(
            config.iotBaseUrl + data.status ? `enable` : 'disable',
          )
          const res = await request().post(
            url,
            { imei: data.imei },
            {
              headers: {
                token: `${config.socketToken}`,
              },
            },
          )
          alert(res.data.message)
          return res.data.data
        } catch (error) {
          console.log(error.response)
          return error.response
        }
      }
    },
    // Refill Gas
    async refillGas({ commit, rootState, state }, imei) {
      if (confirm('Refill Gas?')) {
        try {
          let url = new URL(config.iotBaseUrl + `refill_gas`)
          const res = await request().post(
            url,
            { imei: imei },
            {
              headers: {
                token: `${config.socketToken}`,
              },
            },
          )
          alert(res.data.message)
          // console.log(res)
          return res.data.data
        } catch (error) {
          console.log(error.response)
          return error.response
        }
      }
    },

    // fetch block data from iot server.
    async fetchBlockData({ commit, rootState, state }, imei) {
      try {
        let url = new URL(config.iotBaseUrl + `block/detail?reference=${imei}`)
        const res = await request().get(url, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        return res.data.data
      } catch (error) { }
    },

    // Fetch AutoGen Data - Hey steve, the method above can still be used to get autogen device data
    async fetchAutoGenData({ commit, rootState, state }, imei) {
      // console.log(imei)
      try {
        // console.log(config.socketToken)
        let url = new URL(config.iotBaseUrl + `device/detail?imei=${imei}`)
        const res = await request().get(url, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        commit('SET_AUTOGEN_DATA', res.data)
        return res.data
      } catch (error) { }
    },

    // Fetch AutoGen Data - New Endpoint for Generator Log
    async fetchGenLog({ commit, rootState, state }, imei) {
      try {
        let url = new URL(
          config.iotBaseUrl + `generator_log?imei=${imei}&date_taken=${today}`,
        )
        const res = await request().get(url, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        commit('SET_GEN_LOG', res.data)
        return res.data
      } catch (error) {
        // console.log(error.response)
        commit('SET_GEN_LOG', error.response.data.data)
        return error.response
      }
    },

    // Fetch AutoGen Data Runtime
    async fetchGenRuntime({ commit, rootState, state }, imei) {
      try {
        let url = new URL(
          config.iotBaseUrl +
          `generator_runtime?imei=${imei}&date_taken=${today}`,
        )
        const res = await request().get(url, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        commit('SET_GEN_RUNTIME', res.data)
        return res.data
      } catch (error) {
        // console.log(error.response.data.data)
        commit('SET_GEN_RUNTIME', error.response.data.data)
        return error.response.data.data[0]
      }
    },

    // IOT ENABLE DEVICE
    async iotEnableDevice({ commit }, data) {

      console.log(config.socketToken)
      let url = new URL(
        config.iotBaseUrl +
        `device/enable`,
      )
      try {
        let res = await request().put(url, { imei: data.imei }, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        alert(res.data.message)
        return res
      } catch (error) {
        return error.response
      }

    },
    async iotDisableDevice({ commit }, data) {

      let url = new URL(
        config.iotBaseUrl +
        `device/disable`,
      )

      try {
        let res = await request().put(url, { imei: data.imei }, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        alert(res.data.message)
        return res
      } catch (error) {
        return error.response
      }
    },
    async setKikeCookingTime({ commit }, data) {

      let url = new URL(
        config.iotBaseUrl +
        `setKikeCookingTime`,
      )
      if (confirm('Set cooking Time?')) {

        try {
          let res = await request().put(url, { imei: data.imei, cookTime: data.cookTime }, {
            headers: {
              token: `${config.socketToken}`,
            },
          })
          alert(res.data.message)
          return res
        } catch (error) {
          return error.response
        }

      }
      else {
        return {
          confirmed: false
        }
      }
    },
    async cancelKikeCookingTime({ commit }, data) {

      let url = new URL(
        config.iotBaseUrl +
        `cancelKikeCookingTime`,
      )

      try {
        let res = await request().put(url, { imei: data.imei }, {
          headers: {
            token: `${config.socketToken}`,
          },
        })
        alert(res.data.message)
        return res
      } catch (error) {
        return error.response
      }
    },


    // ENABLE DEVICE
    async enableDevice({ commit }, data) {
      console.log(data);
      if (confirm('Enable Device?')) {
        try {
          let res = await lifestylerequest().post(`/devices/${data.imei}/enable`, { imei: data.imei }, {
            'Content-Type': 'application/json',
          })
          alert(res.data.message)
          return res
        } catch (error) {
          return error.response
        }
      }
    },
    // DISABLE DEVICE
    async disableDevice({ commit }, data) {
      if (confirm('Disable Device?')) {
        try {
          let res = await lifestylerequest().post(`/devices/${data.imei}/disable`, { imei: data.imei }, {
            'Content-Type': 'application/json',
          })
          alert(res.data.message)
          return res
        } catch (error) {
          return error.response
        }
      }
    },
    // SWITCH DEVICE
    async switchDevice({ commit }, data) {

      if (confirm(`${data.status ? 'Enable' : 'Disable'} Device?`)) {
        try {
          let res = await lifestylerequest().post(`/devices/${data.imei}/switch/${data.status ? 'on' : 'off'}`, {
            'Content-Type': 'application/json',
          })
          alert(res.data.message)
          return res
        } catch (error) {
          alert(error.response.data.message)
          return error.response
        }
      }
    },
  },
  getters: {
    // getProduct: (state) => (id) => {
    //   return state.options.find((option) => option.name === id)
    // },
    // getSingleProduct: (state) => (id) => {
    //   return state.products.filter((option) => option.categories[0].name === id)
    // },
    // getAccessory: (state) => (id) => {
    //   state.accessory = state.accessories.find(
    //     (accessory) => accessory.name === id,
    //   )
    // },
    getSocketAuth: (state) => {
      return state.socket_auth
    },
    getUserProducts: (state) => state.user_products,
  },
}
