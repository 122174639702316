const HomePage = () => import('../views/HomePage')
const ShopPage = () => import('../views/ShopPage')
const SolarPromo = () => import('../views/SolarPromo')
const Products = () => import('../views/Products')
const Product = () => import('../views/Product')
const Details = () => import('../views/Details')
const Cart = () => import('../views/Cart')
const BuyEnergy = () => import('../views/BuyEnergy')
const Accessory = () => import('../views/Accessory')
const Swap = () => import('../views/Swap')
const OwattsScoreHome = () => import('../views/OwattsScore/views/Home')

export default [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: ShopPage,
  },
  {
    path: '/shop/promo/:id',
    name: 'SolarPromo',
    component: SolarPromo,
  },
  {
    path: '/details/:id/:name',
    name: 'Product',
    component: Product,
  },
  {
    path: '/shop/:id',
    name: 'Shop - Product',
    component: Products,
  },
  {
    path: '/shop/:id/:name',
    name: 'Shop - Product Details',
    component: Details,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/shop/:id/:name',
    name: 'Shop - Product Details Info',
    component: Product,
  },
  {
    path: '/shop/accessory/:name',
    name: 'Shop - Product Accessory',
    component: Accessory,
  },
  {
    path: '/:id//swap-batteries',
    name: 'swap.batteries',
    component: Swap,
  },

  // OWATTS
  {
    path: '/owatts_score',
    name: 'Owatts - Score',
    component: OwattsScoreHome,
  },
  {
    path: '/buy-energy',
    name: 'Owatts - Buy Energy',
    component: BuyEnergy,
  },
]
