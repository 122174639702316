const UserInvoices = () => import('../views/user/Index')
const UserInvoice = () => import('../views/user/Show')

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store/index'

export default [
  {
    path: '/invoices',
    name: 'UserInvoices',
    component: DefaultLayout,
    redirect: '/wallet',
    async beforeEnter(to, from, next) {
      if (screen.width <= 768) {
        // store.commit({
        //   type: 'updateSidebarVisible',
        //   value: store.state.sidebarVisible,
        // })
        store.commit('setSidebar', false)
      }
      try {
        var hasPermission = await store.getters.get_access_token
        if (hasPermission) {
          next()
        } else {
          next({
            name: 'Login', // back to safety route //
            query: { redirectFrom: to.fullPath },
          })
        }
      } catch (e) {
        next({
          name: 'Login', // back to safety route //
          query: { redirectFrom: to.fullPath },
        })
      }
    },
    children: [
      {
        path: '/invoices',
        name: 'user.invoices.index',
        component: UserInvoices,
      },
      {
        path: '/invoices/:id',
        name: 'user.invoices.show',
        component: UserInvoice,
        meta: {
          layout: 'default',
          auth: true,
          guard: 'user',
          title: 'Invoice  Details',
        },
      },
    ],
  },
]
