<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left" v-if="user_currency">
    <div>
      <MenuButton
        class="font-bold inline-flex justify-center w-full rounded-md px-4 py-2 p-0 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
      >
        {{ user_currency }}

        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              >Currency settings</a
            >
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="user_currency">
            <a
              @click="setCurrency('NGN', 'en-US')"
              href="#"
              :class="[
                user_currency == 'NGN'
                  ? 'active bg-gray-100 text-gray-900'
                  : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              >NGN</a
            >
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="user_currency">
            <a
              @click="setCurrency('USD', 'us')"
              href="javascript:void(0)"
              :class="[
                user_currency == 'USD'
                  ? 'active bg-gray-100 text-gray-900'
                  : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              >USD</a
            >
          </MenuItem>
          <MenuItem v-slot="{ active }" v-if="user_currency">
            <a
              @click="setCurrency('GBC', 'gb')"
              href="#"
              :class="[
                user_currency == 'GBC'
                  ? 'active bg-gray-100 text-gray-900'
                  : 'text-gray-700',
                'block px-4 py-2 text-sm',
              ]"
              >GBC</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

export default {
  data() {
    return {
      payload: {
        currency: '',
        locale: '',
      },
    }
  },

  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },

  computed: {
    ...mapState('auth', ['user_currency']),
    ...mapState('auth', ['currency']),
  },

  methods: {
    ...mapActions('auth', ['changeCurrency']),
    ...mapActions('auth', ['getCurrency']),
    async setCurrency(a, b) {
      let payload = { currency: a, locale: b }
      

      try {
        let res = await this.changeCurrency(payload)
      
        this.$moshaToast(`Currency Updated`, {
          hideProgressBar: true,
          type: 'success',
          position: 'top-right',
        })
      } catch (error) {
      
      }
    },
  },

  async created() {},
}
</script>
