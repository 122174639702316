import axios from 'axios'
import config from '@/config'
import router from '@/router/index'

export default function () {
  let token

  //read and convert to object
  let storeObj = JSON.parse(localStorage.getItem('vuex'))
  if (storeObj && storeObj.auth) {
    token = storeObj.auth.access_token
  }

  // When sending a request, if there is a token, it needs to be attached to the request header
  let instance = axios

  if (token) {
    instance = axios.create({
      baseURL: `${config.baseUrl}`,
      headers: {
        authorization: 'Bearer ' + token,
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        // 'Access-Control-Max-Age': 600,
      },
    })
  } else {
    instance = axios.create({
      baseURL: `${config.baseUrl}`,
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        // 'Access-Control-Max-Age': 600,
      },
    })
  }

  instance.interceptors.response.use(
    (resp) => {
      return resp
    },
    (err) => {
      console.log(err.response)
      // Permission denied.
      if (err.response && err.response.status === 403) {
        return Promise.reject(err)
      }
      if (err.response && err.response.status === 500) {
        // window.location.href = '/login'
        return Promise.reject(err)
      }

      //  Invalid token, Not Authenticated.
      if (err.response && err.response.status === 401) {
    
        if (err.response.statusText === 'Unauthorized') {
          // console.log(900)
          // console.log(err.response);
          if (router.currentRoute.value.fullPath.includes('login')) {
            
          }
          else {
            if (!err.response.message.name === 'SequelizeDatabaseError') {
              localStorage.removeItem('vuex')
              window.location.href = '/login'
            }

            
          }
          localStorage.removeItem('vuex')
        }
      }

      return Promise.reject(err)
    },
  )

  return instance
}
